import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { NewVisitFormType } from '../schema';
import { Textarea } from '@mantine/core';
import { Radio, Input, Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import InputLoader, { inputLoaderState } from '../../InputLoader';

export default function NewVisitAnamnesisData({ loading }: { loading: boolean }) {
  const {
    control,
    formState: { errors },
    getValues,
    watch,
    register,
    setValue,
  } = useFormContext<NewVisitFormType>();

  const serviceModality = watch('serviceModality');
  const { t } = useTranslation('Custom');

  return (
    <>
      {serviceModality !== 'MG' && (
        <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
          <Input.Label className="sm:w-1/3">{t('Claim')}</Input.Label>
          <Textarea
            {...register('complaints')}
            error={errors.complaints?.message}
            className="sm:w-2/3"
            {...inputLoaderState(loading)}
          />
        </div>
      )}

      {serviceModality === 'MG' && (
        <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
          <Input.Label className="sm:w-1/3">Тип посещения</Input.Label>
          <Controller
            name="visit_type"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  clearable
                  {...field}
                  data={[
                    { value: 'PRIMARY', label: 'Первичный' },
                    { value: 'REPEATED', label: 'Повторный' },
                  ]}
                  error={errors[field.name]?.message}
                  className="sm:w-2/3"
                  {...inputLoaderState(loading)}
                />
              );
            }}
          />
        </div>
      )}

      <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
        <Input.Label className="sm:w-1/3">{t('Medical history')}</Input.Label>
        <Textarea
          {...register('medical_history')}
          error={errors.medical_history?.message}
          className="sm:w-2/3"
          {...inputLoaderState(loading)}
        />
      </div>

      {serviceModality !== 'MG' &&
        (
          [
            {
              conditionRadioName: 'has_postponed_operations',
              conditionRadioLabel: t('Postponed operations'),
              inputName: 'postponed_operations',
            },

            {
              conditionRadioName: 'has_metalforeign_bodies',
              conditionRadioLabel: t('Metalforeign bodies'),
              inputName: 'metalforeign_bodies',
            },

            {
              conditionRadioName: 'has_electronic_devices',
              conditionRadioLabel: t('Electronic devices'),
              inputName: 'electronic_devices',
            },
          ] as const
        ).map(inputBlock => {
          watch(inputBlock.conditionRadioName);

          return (
            <div
              className="mt-4 flex flex-col gap-3 sm:flex-row"
              key={inputBlock.inputName}
            >
              <Input.Label
                className="sm:w-1/3"
                required
              >
                {inputBlock.conditionRadioLabel}
              </Input.Label>
              <div className="sm:w-2/3">
                <div>
                  <div className="flex items-center gap-4">
                    {loading && (
                      <div className="ml-[9px]">
                        <InputLoader loading={true} />
                      </div>
                    )}

                    <Radio
                      {...register(inputBlock.conditionRadioName)}
                      label={t('Yes')}
                      value="yes"
                      onClick={() => setValue(inputBlock.inputName, '')}
                      readOnly={loading}
                    />

                    <Radio
                      {...register(inputBlock.conditionRadioName)}
                      label={t('No')}
                      value="no"
                      onClick={() => setValue(inputBlock.inputName, '')}
                      readOnly={loading}
                    />
                  </div>
                  <Input.Error className="mt-2">
                    {errors[inputBlock.conditionRadioName]?.message}
                  </Input.Error>
                </div>

                <div className="mt-1 sm:mt-4">
                  {getValues()[inputBlock.conditionRadioName] === 'yes' && (
                    <Textarea
                      {...register(inputBlock.inputName)}
                      error={errors[inputBlock.inputName]?.message}
                      {...inputLoaderState(loading)}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}
