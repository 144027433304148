import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getOptimalDataSource, getStudyInstancesTags } from '../../../utils/orthancUtils';
import { Studies } from '../types/StudyTypes';
import { getMgFramePos } from '../../../utils/utils';

type MammoInstancesCount = { [key in 'R-CC' | 'L-CC' | 'R-MLO' | 'L-MLO']?: number };

export function useGetMammoInstancesCount(
  { study }: { study?: Studies },
  opts: UseQueryOptions<MammoInstancesCount | null>
) {
  return useQuery({
    queryKey: ['instancesCount', study?.ohif_id],
    queryFn: async () => {
      if (!study) {
        return null;
      }
      const optimalDataSource = await getOptimalDataSource(
        study.organizations!.orthanc_config[0] || null,
        [],
        study.ohif_id!
      );

      const countMap: MammoInstancesCount = {};

      const studyInstance = await getStudyInstancesTags(study.ohif_id!, optimalDataSource);
      if (!studyInstance) {
        return null;
      }
      for (const series of studyInstance.mapped.series) {
        for (const instanceTags of series.mapped.instancesTags) {
          const mgPos = getMgFramePos(instanceTags);
          if (mgPos) {
            countMap[mgPos] =
              (countMap[mgPos] || 0) +
              (instanceTags.NumberOfFrames ? +instanceTags.NumberOfFrames : 1);
          }
        }
      }
      return countMap;
    },
    ...opts,
  });
}
