import { trpc } from '../../trpc';

export function useGetStudy(ohif_id?: string | number, byId = false) {
  // OHIF doesn't wrap modals by <Router>. We have to get id by window.location
  const currentStudyUid = new URLSearchParams(window.location.search).get('StudyInstanceUIDs');
  const studyUid = ohif_id || currentStudyUid || '';

  const data = trpc.study.get.useQuery({ 
    ohif_id: byId ? undefined : studyUid as string, 
    id: byId ? (ohif_id as number) : undefined },
    { enabled: !!studyUid });

  return data;
}
