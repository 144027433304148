import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { CnFirstMammoForm } from '../CnMammo';
import { useGetBiradsLocs } from '../../../../../../platform/hooks/useGetBiradsLocs';
import { Select } from '@mantine/core';

const CnMammoLocs = ({ disabled }: { disabled: boolean }) => {
  const formContext = useFormContext<CnFirstMammoForm>();
  const mg_quality = formContext.watch('mg_quality');

  const { options } = useGetBiradsLocs();

  const biradFields = [
    {
      inputName: 'localization_right',
      label: 'Локализация R:',
      options: options.localizations,
      side: 'right',
    },
    {
      inputName: 'localization_left',
      label: 'Локализация L:',
      options: options.localizations,
      side: 'left',
    },
  ] as const;

  return (
    <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
      {biradFields.map(({ inputName, label, options, side }) => {
        return (
          <div key={inputName}>
            <div className="flex flex-col">
              <Controller
                name={inputName}
                control={formContext.control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      label={label}
                      data={options}
                      error={formContext.formState.errors[inputName]?.message}
                      readOnly={disabled || mg_quality[side] !== 'GOOD'}
                    />
                  );
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CnMammoLocs;
