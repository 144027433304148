import dayjs from 'dayjs';
import { Decimal } from 'decimal.js';
import UserService from '../app/src/userService';

export function getMgFramePos(instanceTags: any): 'R-CC' | 'R-MLO' | 'L-CC' | 'L-MLO' | null {
  const viewCode = instanceTags.ViewCodeSequence?.[0]?.CodeValue;
  const laterality =
    instanceTags.ImageLaterality ||
    instanceTags.Laterality ||
    instanceTags.SharedFunctionalGroupsSequence?.[0]?.FrameAnatomySequence?.[0]?.FrameLaterality;

  const typeToCodeMap = {
    CC: 'R-10242',
    MLO: 'R-10226',
  };

  if (laterality === 'R') {
    if (viewCode === typeToCodeMap['CC']) return 'R-CC';
    if (viewCode === typeToCodeMap['MLO']) return 'R-MLO';
  }

  if (laterality === 'L') {
    if (viewCode === typeToCodeMap['CC']) return 'L-CC';
    if (viewCode === typeToCodeMap['MLO']) return 'L-MLO';
  }

  return null;
}

export function getAge(date?: string | Date | null) {
  const dayjsDate = dayjs(date);
  return date && dayjsDate.isValid() ? dayjs().diff(dayjsDate, 'year') : '';
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function getDicomEntranceDoseInmGy(series: any) {
  if (
    !series?.find(
      (s: any) => s.instances?.some((i: any) => i.EntranceDoseInmGy || i.EntranceDoseInmGy === 0)
    )
  ) {
    return '';
  }

  const res =
    series?.reduce(
      (acc: any, s: any) =>
        new Decimal(acc).plus(
          new Decimal(
            (s.instances || []).reduce(
              (innerAcc: any, i: any) =>
                new Decimal(innerAcc).plus(new Decimal(+i.EntranceDoseInmGy || 0)),
              0
            )
          )
        ),
      0
    ) || 0;

  return new Decimal(res).mul(new Decimal(0.2)).mul(new Decimal(0.12)).toFixed(2).toString();
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function getDicomEntranceDoseInmGyV2(series: any) {
  const entranceDoseValues = getValuesByKey(series, 'EntranceDoseInmGy');

  if (entranceDoseValues.length === 0) {
    return '';
  }

  const totalEntranceDose = entranceDoseValues.reduce(
    (acc, value) => acc.add(new Decimal(value)),
    new Decimal(0)
  );

  return totalEntranceDose.mul(new Decimal(0.2)).mul(new Decimal(0.12)).toFixed(2).toString();
}

function getValuesByKey(data: any, key: string) {
  const results = [] as any[];

  function search(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach(item => search(item));
    } else if (typeof obj === 'object' && obj !== null) {
      for (const k in obj) {
        if (k === key) {
          results.push(obj[k]);
        }
        search(obj[k]);
      }
    }
  }

  search(data);
  return results;
}

export function findClosestNum(arr: number[], target: number) {
  let left = 0;
  let right = arr.length - 1;
  while (left < right) {
    if (Math.abs(arr[left] - target) <= Math.abs(arr[right] - target)) {
      right--;
    } else {
      left++;
    }
  }
  return arr[left];
}

const sp = new URLSearchParams(window.location.search);
export const tokenBased = sp.get('testToken') === 'true';

export async function getHeader() {
  if (tokenBased) {
    try {
      await UserService.updateToken();
      return `Bearer ${UserService.getToken()}`;
    } catch (e) {
      console.log(e);
      UserService.doLogin();
    }
  } else {
    return 'Basic ' + btoa(`${process.env.ORTHANC_LOGIN}:${process.env.ORTHANC_PASSWORD}`);
  }
}
