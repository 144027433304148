import { object, string, setLocale, array, mixed, boolean, number, date, InferType } from 'yup';
import { checkIinBinSum } from '../../utils/checkIinBinSum';
import { Studies } from '../../app/src/types/StudyTypes';
import dayjs from 'dayjs';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../medreview/server/src';

setLocale({
  mixed: {
    required: 'Это обязательное поле',
    notType: 'Это обязательное поле',
  },
});

export const serviceModalities = {
  CT: {
    label: 'КТ',
    modality_slugs: ['CT'],
  },
  MR: {
    label: 'МРТ',
    modality_slugs: ['MR'],
  },
  DX: {
    label: 'Рентген',
    modality_slugs: ['DX'],
  },
  MG: {
    label: 'Маммография',
    modality_slugs: ['MG'],
  },
  US: {
    label: 'Узи',
    modality_slugs: ['US'],
  },
} as const;
export const documentTypes = ['IIN', 'PASSPORT'] as const;
export const visitTypes = ['PRIMARY', 'REPEATED'] as const;
export const serviceDefaultValues = {
  id: 1,
  service_code: '',
  service_count: 1,
  comment: '',
};

export const schema = object({
  orgId: string().required(),
  serviceModality: string<keyof typeof serviceModalities>()
    .defined()
    .oneOf(Object.keys(serviceModalities) as (keyof typeof serviceModalities)[]),
  document_type: string<(typeof documentTypes)[number]>().defined().oneOf(documentTypes),

  iin: string()
    .when('document_type', (document_type, schema) => {
      return document_type[0] === 'IIN'
        ? schema
            .test(
              'is-valid-iin',
              'Некорректный ИИН',
              val => val?.length === 12 && checkIinBinSum(val)
            )
            .required()
        : schema.min(1, 'Это обязательное поле').required();
    })
    .required(),
  fullname: string().required(),
  bdate: date().required('Введите корректную дату'),
  email: string().email().notRequired(),
  phone: string()
    .test('is-valid-phone', v => v === '' || v?.replace(/[^0-9]/g, '').length === 11)
    .notRequired(),

  payment_type_slug: string().notRequired(),
  insurance_organizations_id: string().when('payment_type_slug', (payment_type_slug, schema) => {
    return payment_type_slug[0] === 'INSURANCE' ? schema.required() : schema.notRequired();
  }),

  services: array()
    .of(
      object({
        id: number().notRequired(),
        service_code: string().notRequired(),
        service_count: number().notRequired(),
        comment: string().notRequired(),
        appointment_id: number().notRequired(),
      })
    )
    .required(),

  treatment_reason_code: string().required(),
  complaints: string().notRequired(),
  medical_history: string().notRequired(),

  has_postponed_operations: string().when(...checkByMg(false)),
  has_metalforeign_bodies: string().when(...checkByMg(false)),
  has_electronic_devices: string().when(...checkByMg(false)),

  postponed_operations: string().when(...reqByField('has_postponed_operations', 'yes')),
  metalforeign_bodies: string().when(...reqByField('has_metalforeign_bodies', 'yes')),
  electronic_devices: string().when(...reqByField('has_electronic_devices', 'yes')),

  responsible_user_id: string().notRequired(),
  first_reader_responsible_user_id: string().notRequired(),
  physician_user_id: string().notRequired(),

  // mammo main
  visit_type: string<(typeof visitTypes)[number]>().notRequired().oneOf(visitTypes),
  is_first_mammo: string().when(...checkByMg()),
  is_first_screening: string().when(...checkByMg()),
  first_screening_data: string().when(...mgReqByField('is_first_screening', 'no')),
  has_other_mg_studies: string().when(...checkByMg()),
  other_mg_studies_data: string().when(...mgReqByField('has_other_mg_studies', 'yes')),
  has_prev_mg_studies: string().when(...checkByMg()),
  has_menopause: string().when(...checkByMg()),
  menopause_age: string().when(...mgReqByField('has_menopause', 'yes')),
  heredity: string().when(...checkByMg()),
  breast_cancer: string().when(...mgReqByField('heredity', 'yes')),
  breast_cancer_other: string().when(...mgReqByField('heredity', 'yes')),
  hormone_replacement_therapy: string().when(...checkByMg()),
  has_complaints: string().when(...checkByMg()),
  complaints_data: string().when(...mgReqByField('has_complaints', 'yes')),

  // mammo anamnesis
  cyst_aspiration: RL<{ right: Date; left: Date }>(date),
  sector_resection: RL<{ right: Date; left: Date }>(date),
  mastectomy: RL<{ right: Date; left: Date }>(date),
  organ_reservation_operation: RL<{ right: Date; left: Date }>(date),
  radiation_therapy: RL<{ right: Date; left: Date }>(date),
  other_operations: RL<{ right: Date; left: Date }>(date),

  // visible changes of the gland
  scar_mole_papilloma: RL<{ right: boolean; left: boolean }>(boolean),
  nipple_changes: RL<{ right: boolean; left: boolean }>(boolean),
  nipple_discharge: RL<{ right: boolean; left: boolean }>(boolean),
  deformation_compaction: RL<{ right: boolean; left: boolean }>(boolean),

  // assistant
  assistant_pic: string().notRequired(),
  direct_projection: RL<{ right: string; left: string }>(string),
  oblique_projection: RL<{ right: string; left: string }>(string),
  mammo_total_number: string().notRequired(),
  assistant_note: string().notRequired(),
  assistant_date: date().notRequired(),
  closed_on_damumed: boolean().notRequired(),
});

export type NewVisitFormType = InferType<typeof schema>;

function RL<T>(func, required = false) {
  return mixed<T>().when('serviceModality', (serviceModality, schema) => {
    return serviceModality[0] === 'MG'
      ? required
        ? object({
            right: func().required(),
            left: func().required(),
          })
        : object({
            right: func().notRequired(),
            left: func().notRequired(),
          })
      : schema.notRequired();
  });
}

function mgReqByField(field, condition: 'yes' | 'no') {
  return [
    'serviceModality',
    (serviceModality, schema) => {
      return serviceModality[0] === 'MG'
        ? schema.when(...reqByField(field, condition))
        : schema.notRequired();
    },
  ] as const;
}

function reqByField(field, condition: 'yes' | 'no') {
  return [field, (v, sch) => (v[0] === condition ? sch.required() : sch.notRequired())] as const;
}

function checkByMg(whenMg = true) {
  return [
    'serviceModality',
    (serviceModality, schema) => {
      const condition = whenMg ? serviceModality[0] === 'MG' : serviceModality[0] !== 'MG';
      return condition ? schema.required() : schema.notRequired();
    },
  ] as const;
}

export function getBdate(bdate?: string | null, iin?: string | null) {
  if (bdate) {
    return dayjs(bdate).toDate();
  }
  if(iin) {
    const iinDate = dayjs(iin.slice(0, 6), 'YYMMDD');
    return iinDate.isValid() ? iinDate.toDate() : null;
  }
  return null;
}

export function hasDisease(
  property: keyof Pick<
    Studies['anamesis'][number],
    'postponed_operations' | 'metalforeign_bodies' | 'electronic_devices'
  >,
  anamnesis?: NonNullable<
    inferRouterOutputs<AppRouter>['visit']['getVisitPatientData']
  >['anamesis'][number]
) {
  if (!anamnesis) {
    return '';
  }

  return anamnesis?.[property]
    ? ['НЕТ', 'ЖОК', 'ЖОҚ'].some(detectStr => anamnesis[property]?.toUpperCase() === detectStr)
      ? 'no'
      : 'yes'
    : 'no';
}

export function extractServiceTypeByModalities(modalitiesList: string[]) {
  return modalitiesList.includes('CT')
    ? 'CT'
    : modalitiesList.includes('MR')
      ? 'MR'
      : modalitiesList.includes('US')
        ? 'US'
        : modalitiesList.includes('MG')
          ? 'MG'
          : modalitiesList.includes('DX')
            ? 'DX'
            : undefined;
}

export function getStringBool(val?: string) {
  if (!val) {
    return null;
  }
  return val === 'yes';
}
