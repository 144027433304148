import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NewVisitFormType } from '../../schema';
import { Input } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export default function MammoVisitAnamnesis() {
  const {
    control,
    formState: { errors },
  } = useFormContext<NewVisitFormType>();
  const { t } = useTranslation('Custom');

  const fieldBlocks = [
    {
      name: 'cyst_aspiration',
      label: t('Cyst aspiration'),
    },
    {
      name: 'sector_resection',
      label: t('Sector resection'),
    },
    {
      name: 'mastectomy',
      label: t('Mastectomy'),
    },
    {
      name: 'organ_reservation_operation',
      label: t('Organ reservation operation'),
    },
    {
      name: 'radiation_therapy',
      label: t('Radiation therapy'),
    },
    {
      name: 'other_operations',
      label: t('Other operations'),
    },
  ] as const;

  return (
    <>
      <fieldset className="mt-12 border p-4">
        <legend className="px-4 font-bold">{t('Anamnesis. Year')}</legend>

        <div className="mt-4 flex flex-col gap-3 sm:flex-row">
          <div className="sm:w-1/3"></div>
          <div className="w-2/3">
            <div className="flex gap-4">
              <p className="w-32 text-center">R</p>
              <p className="w-32 text-center">L</p>
            </div>
          </div>
        </div>

        {fieldBlocks.map(fieldBlock => {
          return (
            <div
              className="mt-4 flex flex-col gap-3 sm:flex-row"
              key={fieldBlock.name}
            >
              <Input.Label className="sm:w-1/3">{fieldBlock.label}</Input.Label>
              <div className="w-2/3">
                <div className="flex gap-4">
                  <Controller
                    name={`${fieldBlock.name}.right`}
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        dateParser={input => dayjs(input, 'DD.MM.YYYY').toDate()}
                        placeholder="ДД.ММ.ГГГГ"
                        size="xs"
                        valueFormat="DD.MM.YYYY"
                        className="w-32"
                        error={errors[fieldBlock.name]?.right?.message}
                      />
                    )}
                  />

                  <Controller
                    name={`${fieldBlock.name}.left`}
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        dateParser={input => dayjs(input, 'DD.MM.YYYY').toDate()}
                        placeholder="ДД.ММ.ГГГГ"
                        size="xs"
                        valueFormat="DD.MM.YYYY"
                        className="w-32"
                        error={errors[fieldBlock.name]?.left?.message}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </fieldset>
    </>
  );
}
