import html2canvas from 'html2canvas';
import { DefaultForm } from './CnDefault';
import { servicesManager } from '../../../../../platform/app/src/App';
import { CornerstoneServices } from '../../../../../extensions/cornerstone';
import { getActiveViewportEnabledElement } from '../../../../../extensions/cornerstone/src';
import { ToolGroupManager } from '@cornerstonejs/tools';
import { notifications } from '@mantine/notifications';

export async function appendAttachmentsBase(attachments: DefaultForm['attachments']) {
  const res = [];

  // we can't use useRef to gel elements of cornerstone generated viewport
  for (const attachment of Array.from(
    document.querySelectorAll('[data-viewer-id^="attachment-large"]')
  )) {
    const viewport = attachment.querySelector('.viewport-element') as HTMLElement;
    if (!viewport) {
      continue;
    }
    const canvas = await html2canvas(viewport);
    const blob = await new Promise<Blob | null>(resolve => {
      canvas.toBlob(blob => resolve(blob));
    });

    if (blob) {
      res.push(await blobToBase64(blob));
    }
  }

  const clipboardAttachments = attachments.filter(a => a.type === 'CLIPBOARD');

  for (const a of clipboardAttachments) {
    res.push(await blobToBase64(a.metadata.blob));
  }

  return res;
}

export async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onerror = e => reject(fileReader.error);
    fileReader.onloadend = e => {
      const dataUrl = fileReader.result as string;
      // remove "data:mime/type;base64," prefix from data url
      const base64 = dataUrl.substring(dataUrl.indexOf(',') + 1);
      resolve(base64);
    };
    fileReader.readAsDataURL(blob);
  });
}

export function getRenderTools() {
  const { cornerstoneViewportService, viewportGridService } =
    servicesManager.services as CornerstoneServices;

  const renderingEngine = cornerstoneViewportService.getRenderingEngine();
  const enabledElem = getActiveViewportEnabledElement(viewportGridService);
  const toolGroup = ToolGroupManager.getToolGroupForViewport(
    enabledElem.viewportId,
    enabledElem.renderingEngineId
  );

  const defaultOptions = {
    background: enabledElem.viewport.defaultOptions.background,
    orientation: enabledElem.viewport.defaultOptions.orientation,
  };

  return { renderingEngine, enabledElem, toolGroup, defaultOptions };
}

export async function getClipboardImgBlob() {
  const clipboardItems = await navigator.clipboard.read();

  for (const item of clipboardItems) {
    const imageType = item.types.find(type => type.startsWith('image/'));

    if (!imageType) {
      notifications.show({
        message: `Сначала скопируйте картинку в буфер обмена. В Windows 10/11 нажмите
          сочетание клавиш win + shift + s и выделите участок кадра`,
        color: 'orange',
      });

      return;
    }

    return await item.getType(imageType);
  }
}
