import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'idle',
  type: null,
};

const ArchiveStatusSlice = createSlice({
  name: 'archiveStatus',
  initialState: initialState,
  reducers: {
    setArchiveStatus(state, action) {
      state.status = action.payload.status;
      state.type = action.payload.type;
    },
  },
});

export const { setArchiveStatus } = ArchiveStatusSlice.actions;

export default ArchiveStatusSlice.reducer;
