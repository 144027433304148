import { fourUp } from './hps/fourUp';
import { main3D } from './hps/main3D';
import { mpr } from './hps/mpr';
import { mprAnd3DVolumeViewport } from './hps/mprAnd3DVolumeViewport';
import { only3D } from './hps/only3D';
import { primary3D } from './hps/primary3D';
import { primaryAxial } from './hps/primaryAxial';

const mgCCProtocol = {
  id: 'mgCC',
  name: 'mammo',
  imageLoadStrategy: 'interleaveTopToBottom',
  protocolMatchingRules: [
    { attribute: 'ModalitiesInStudy', constraint: { contains: ['MG', 'DX'] } },
  ],
  displaySetSelectors: {
    isRCC: {
      seriesMatchingRules: [
        { weight: 3, attribute: 'isRCC', constraint: { equals: { value: true } } },
        { weight: 2, attribute: 'SeriesNumber', constraint: { equals: { value: 1 } } },
        { weight: 1, attribute: 'any', constraint: { equals: { value: true } } },
      ],
    },
    isLCC: {
      seriesMatchingRules: [
        { weight: 3, attribute: 'isLCC', constraint: { equals: { value: true } } },
        { weight: 2, attribute: 'SeriesNumber', constraint: { equals: { value: 2 } } },
        { weight: 1, attribute: 'any', constraint: { equals: { value: true } } },
      ],
    },
  },
  stages: [
    {
      name: 'default',
      viewportStructure: { layoutType: 'grid', properties: { rows: 1, columns: 2 } },
      viewports: [{ displaySets: [{ id: 'isRCC' }] }, { displaySets: [{ id: 'isLCC' }] }],
    },
  ],
  numberOfPriorsReferenced: -1,
};

const mgMLOProtocol = {
  id: 'mgMLO',
  name: 'mammo',
  imageLoadStrategy: 'interleaveTopToBottom',
  protocolMatchingRules: [
    { attribute: 'ModalitiesInStudy', constraint: { contains: ['MG', 'DX'] } },
  ],
  displaySetSelectors: {
    isRMLO: {
      seriesMatchingRules: [
        { weight: 3, attribute: 'isRMLO', constraint: { equals: { value: true } } },
        { weight: 2, attribute: 'SeriesNumber', constraint: { equals: { value: 3 } } },
        { weight: 1, attribute: 'any', constraint: { equals: { value: true } } },
      ],
    },
    isLMLO: {
      seriesMatchingRules: [
        { weight: 3, attribute: 'isLMLO', constraint: { equals: { value: true } } },
        { weight: 2, attribute: 'SeriesNumber', constraint: { equals: { value: 4 } } },
        { weight: 1, attribute: 'any', constraint: { equals: { value: true } } },
      ],
    },
  },
  stages: [
    {
      name: 'default',
      viewportStructure: { layoutType: 'grid', properties: { rows: 1, columns: 2 } },
      viewports: [{ displaySets: [{ id: 'isRMLO' }] }, { displaySets: [{ id: 'isLMLO' }] }],
    },
  ],
  numberOfPriorsReferenced: -1,
};

function getHangingProtocolModule() {
  return [
    {
      name: mpr.id,
      protocol: mpr,
    },
    {
      name: mprAnd3DVolumeViewport.id,
      protocol: mprAnd3DVolumeViewport,
    },
    {
      name: fourUp.id,
      protocol: fourUp,
    },
    {
      name: main3D.id,
      protocol: main3D,
    },
    {
      name: primaryAxial.id,
      protocol: primaryAxial,
    },
    {
      name: only3D.id,
      protocol: only3D,
    },
    {
      name: primary3D.id,
      protocol: primary3D,
    },
    {
      name: mgCCProtocol.id,
      protocol: mgCCProtocol,
    },
    {
      name: mgMLOProtocol.id,
      protocol: mgMLOProtocol,
    },
  ];
}

export default getHangingProtocolModule;
