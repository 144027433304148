import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Thumbnail from '../Thumbnail';
import ThumbnailNoImage from '../ThumbnailNoImage';
import ThumbnailTracked from '../ThumbnailTracked';
import * as Types from '../../types';
import { servicesManager } from '../../../../app/src/App';
import { getMgFramePos, findClosestNum } from '../../../../utils/utils';
import { mgPosMap } from '../../../../constants';
import { useGetStudy } from '../../../../app/src/api/studies';

const ThumbnailList = props => {
  const study = useGetStudy();
  const isMammo = study.data?.modality_study.find(ms => ms.modalities.name === 'MG');

  return (
    <div
      id="ohif-thumbnail-list"
      className="ohif-scrollbar study-min-height overflow-y-hidden bg-black py-5"
    >
      {isMammo ? <ThumbnailListMg {...props} /> : <Thumbnails {...props} />}
    </div>
  );
};

const Thumbnails = ({
  thumbnails,
  onThumbnailClick,
  onThumbnailDoubleClick,
  onClickUntrack,
  activeDisplaySetInstanceUIDs = [],
}) => {
  return (
    <>
      {thumbnails.map(
        ({
          displaySetInstanceUID,
          description,
          dragData,
          seriesNumber,
          numInstances,
          modality,
          componentType,
          seriesDate,
          countIcon,
          isTracked,
          canReject,
          onReject,
          imageSrc,
          messages,
          imageAltText,
          isHydratedForDerivedDisplaySet,
        }) => {
          const isActive = activeDisplaySetInstanceUIDs.includes(displaySetInstanceUID);
          switch (componentType) {
            case 'thumbnail':
              return (
                <Thumbnail
                  key={displaySetInstanceUID}
                  displaySetInstanceUID={displaySetInstanceUID}
                  dragData={dragData}
                  description={description}
                  seriesNumber={seriesNumber}
                  numInstances={numInstances}
                  countIcon={countIcon}
                  imageSrc={imageSrc}
                  imageAltText={imageAltText}
                  messages={messages}
                  isActive={isActive}
                  onClick={() => onThumbnailClick(displaySetInstanceUID)}
                  onDoubleClick={() => onThumbnailDoubleClick(displaySetInstanceUID)}
                />
              );
            case 'thumbnailTracked':
              return (
                <ThumbnailTracked
                  key={displaySetInstanceUID}
                  displaySetInstanceUID={displaySetInstanceUID}
                  dragData={dragData}
                  description={description}
                  seriesNumber={seriesNumber}
                  numInstances={numInstances}
                  countIcon={countIcon}
                  imageSrc={imageSrc}
                  imageAltText={imageAltText}
                  messages={messages}
                  isTracked={isTracked}
                  isActive={isActive}
                  onClick={() => onThumbnailClick(displaySetInstanceUID)}
                  onDoubleClick={() => onThumbnailDoubleClick(displaySetInstanceUID)}
                  onClickUntrack={() => onClickUntrack(displaySetInstanceUID)}
                />
              );
            case 'thumbnailNoImage':
              return (
                <ThumbnailNoImage
                  isActive={isActive}
                  key={displaySetInstanceUID}
                  displaySetInstanceUID={displaySetInstanceUID}
                  dragData={dragData}
                  modality={modality}
                  modalityTooltip={_getModalityTooltip(modality)}
                  messages={messages}
                  seriesDate={seriesDate}
                  description={description}
                  canReject={canReject}
                  onReject={onReject}
                  onClick={() => onThumbnailClick(displaySetInstanceUID)}
                  onDoubleClick={() => onThumbnailDoubleClick(displaySetInstanceUID)}
                  isHydratedForDerivedDisplaySet={isHydratedForDerivedDisplaySet}
                />
              );
            default:
              return <></>;
          }
        }
      )}
    </>
  );
};

const ThumbnailListMg = props => {
  const groupedThumbnails = useMemo(
    () => getMgGroupedThumbnails(props.thumbnails),
    [props.thumbnails]
  );

  return (
    <>
      {groupedThumbnails.map(thumbnails => {
        return (
          <div
            className={thumbnails.length > 1 ? 'flex' : ''}
            key={thumbnails[0].displaySetInstanceUID}
          >
            <Thumbnails
              {...props}
              thumbnails={thumbnails}
            />
          </div>
        );
      })}
    </>
  );
};

ThumbnailList.propTypes = {
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      displaySetInstanceUID: PropTypes.string.isRequired,
      imageSrc: PropTypes.string,
      imageAltText: PropTypes.string,
      seriesDate: PropTypes.string,
      seriesNumber: Types.StringNumber,
      numInstances: PropTypes.number,
      description: PropTypes.string,
      componentType: Types.ThumbnailType.isRequired,
      isTracked: PropTypes.bool,
      /**
       * Data the thumbnail should expose to a receiving drop target. Use a matching
       * `dragData.type` to identify which targets can receive this draggable item.
       * If this is not set, drag-n-drop will be disabled for this thumbnail.
       *
       * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
       */
      dragData: PropTypes.shape({
        /** Must match the "type" a dropTarget expects */
        type: PropTypes.string.isRequired,
      }),
    })
  ),
  activeDisplaySetInstanceUIDs: PropTypes.arrayOf(PropTypes.string),
  onThumbnailClick: PropTypes.func.isRequired,
  onThumbnailDoubleClick: PropTypes.func.isRequired,
  onClickUntrack: PropTypes.func.isRequired,
};

// TODO: Support "Viewport Identificator"?
function _getModalityTooltip(modality) {
  if (_modalityTooltips.hasOwnProperty(modality)) {
    return _modalityTooltips[modality];
  }

  return 'Unknown';
}

const _modalityTooltips = {
  SR: 'Structured Report',
  SEG: 'Segmentation',
  RTSTRUCT: 'RT Structure Set',
};

function getMgGroupedThumbnails(thumbnails) {
  const { displaySetService } = servicesManager.services;
  const mgGroupedThumbnails = [];
  const seenUuids: string[] = [];

  function addThumbnails(thumbnailsToAdd) {
    mgGroupedThumbnails.push(thumbnailsToAdd);
    seenUuids.push(...thumbnailsToAdd.map(th => th.displaySetInstanceUID));
  }

  for (const th of thumbnails) {
    if (seenUuids.includes(th.displaySetInstanceUID)) {
      continue;
    }

    if (!['MG', 'DX'].includes(th.modality)) {
      addThumbnails([th]);
      continue;
    }

    const displaySet = displaySetService.getDisplaySetByUID(th.displaySetInstanceUID);
    const pos = getMgFramePos(displaySet.instances[0]);

    if (!pos) {
      addThumbnails([th]);
      continue;
    }

    const oppositePosThs = thumbnails.filter(th => {
      const displaySet = displaySetService.getDisplaySetByUID(th.displaySetInstanceUID);
      return (
        !seenUuids.includes(th.displaySetInstanceUID) &&
        getMgFramePos(displaySet.instances[0]) === mgPosMap[pos]
      );
    });

    if (oppositePosThs.length === 0) {
      addThumbnails([th]);
      continue;
    }

    const closestOppositeTh = oppositePosThs.find(
      l =>
        l.numInstances ===
        findClosestNum(
          oppositePosThs.map(lc => lc.numInstances),
          th.numInstances
        )
    );

    const rightSideTh = pos.startsWith('R') ? th : closestOppositeTh;
    const leftSideTh = pos.startsWith('L') ? th : closestOppositeTh;

    addThumbnails([rightSideTh, leftSideTh]);
  }

  return mgGroupedThumbnails;
}

export default ThumbnailList;
