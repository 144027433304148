export function removeTagsAndCopy(text: string) {
  let strippedString = text.replace(/(<\/([^>]+)>)/gi, ' ');
  strippedString = strippedString.replace(/(<([^>]+)>)/gi, '');

  navigator.clipboard.writeText(strippedString);
}

export function highlightSearchTerm(content: string, searchTerm: string) {
  let regex = new RegExp(searchTerm, 'gi');

  let highlightedContent = content.replace(regex, '<span style="color: darkorange;">$&</span>');

  return highlightedContent;
}

export function removeNegativeStyles(inputString: string, propertiesToRemove: string[]): string {
  const regex = new RegExp(`(${propertiesToRemove.join('|')}):\\s*-[^;]+;`, 'g');
  const outputString = inputString.replace(regex, '');
  return outputString;
}

export function capitalize(str: string) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function formatMonth(number: number) {
  number = Math.abs(number);

  const lastTwoDigits = number % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return 'месяцев';
  }

  const lastDigit = number % 10;
  if (lastDigit === 1) {
    return 'месяц';
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    return 'месяца';
  } else {
    return 'месяцев';
  }
}
