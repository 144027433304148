import React, { useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import * as editorLocales from './locales';
import { useTranslation } from 'react-i18next';
import { removeNegativeStyles } from '../../utils/stringUtils';

interface Props {
  data: string;
  onChange: (data: string) => void;
  disabled: boolean;
}

function CkeEditor({ data = '', onChange, disabled = false }: Props) {
  const toolbarRef = useRef<HTMLDivElement | null>(null);

  const { i18n } = useTranslation();
  const currentLocale = i18n.language.split('-')[0];

  window.CKEDITOR_TRANSLATIONS = {
    lng: {
      dictionary: editorLocales[currentLocale || 'ru'],
      getPluralForm: a => a,
    },
  };

  return (
    <div className="document-editor">
      <div
        className="document-editor__toolbar"
        ref={toolbarRef}
      ></div>
      <div className="document-editor__editable-container">
        <CKEditor
          onReady={editor => {
            toolbarRef.current?.appendChild(editor.ui.view.toolbar.element as Node);

            editor.plugins.get('ClipboardPipeline').on(
              'contentInsertion',
              (evt, data) => {
                onChange(
                  removeNegativeStyles(evt.source.editor.getData(), ['margin-left', 'padding-left'])
                );
              },
              { priority: 'lowest' }
            );
          }}
          onChange={(_, editor) => {
            onChange && onChange(editor.getData());
          }}
          editor={DecoupledEditor}
          data={data}
          disabled={disabled}
          config={{
            fontSize: {
              options: [9, 11, 13, 'default', 17, 19, 21],
            },
          }}
        />
      </div>
    </div>
  );
}

export default CkeEditor;
