import { ReactChildren, useEffect, useState } from 'react';
import { extensionManager, servicesManager } from '../App';
import { useGetStudy } from '../api/studies';
import { useSearchParams } from 'react-router-dom';
import { getOptimalDataSource } from '../../../utils/orthancUtils';
import { useUserAuthentication } from '@ohif/ui';
import { trpc } from '../../trpc';
import UserService from '../userService';
import { tokenBased } from '../../../utils/utils';

export default function FetchInitialData({ children }: { children: ReactChildren }) {
  const { userAuthenticationService } = servicesManager.services;
  const [{ user: ohifUser, enabled }] = useUserAuthentication();

  const [dataSourceUrl, setDataSourceUrl] = useState<string | null>(null);

  const { data: user } = trpc.general.getUser.useQuery();
  const { data: study } = useGetStudy();
  const [searchParams] = useSearchParams();
  const studyUid = searchParams.get('StudyInstanceUIDs');

  useEffect(() => {
    userAuthenticationService.set({ enabled: true });
    userAuthenticationService.setServiceImplementation({
      getAuthorizationHeader,
      handleUnauthenticated,
    });
  }, [userAuthenticationService]);

  useEffect(() => {
    if (user) {
      userAuthenticationService.setUser(user);
    }
  }, [user, userAuthenticationService]);

  useEffect(() => {
    async function setDataSource() {
      const optimalDataSource = await getOptimalDataSource(
        study?.organizations?.orthanc_config[0] || null,
        user?.organization_user || [],
        studyUid
      );

      extensionManager.updateDataSourceConfiguration('dicomweb', {
        friendlyName: 'AWS S3 Static wado server',
        name: 'aws',
        wadoUriRoot: `${optimalDataSource}/wado`,
        qidoRoot: `${optimalDataSource}/dicom-web`,
        wadoRoot: `${optimalDataSource}/dicom-web`,
        qidoSupportsIncludeField: false,
        imageRendering: 'wadors',
        thumbnailRendering: 'wadors',
        enableStudyLazyLoad: true,
        supportsFuzzyMatching: false,
        supportsWildcard: true,
        staticWado: true,
        singlepart: 'bulkdata,video',
        bulkDataURI: {
          enabled: true,
          relativeResolution: 'studies',
        },
        omitQuotationForMultipartRequest: true,
      });

      setDataSourceUrl(optimalDataSource);
    }

    if (
      !dataSourceUrl &&
      user?.organization_user &&
      (!studyUid || (studyUid && study?.organizations))
    ) {
      setDataSource();
    }
  }, [dataSourceUrl, study?.organizations, studyUid, user?.organization_user]);

  return user && dataSourceUrl && enabled && ohifUser ? children : null;
}

const getAuthorizationHeader = () => {
  if (tokenBased) {
    UserService.updateToken();
    return {
      Authorization: `Bearer ${UserService.getToken()}`,
    };
  }

  return {
    Authorization: `Basic ${btoa(process.env.ORTHANC_LOGIN + ':' + process.env.ORTHANC_PASSWORD)}`,
  };
};

const handleUnauthenticated = async () => {
  return null;
};
