import { MouseEvent, useEffect, useRef, useState } from 'react';
import React from 'react';
import { Slider, ActionIcon } from '@mantine/core';
import { IconPencil, IconCheck } from '@tabler/icons-react';

interface Props {
  bgImg: string;
  previewImg?: string | null;
  onSave: (dataUrl: string) => void;
}

export default function Drawer({ bgImg, previewImg, onSave }: Props) {
  const canvasElement = useRef<HTMLCanvasElement>(null);
  const [canvasContext, setCanvasContext] = useState<CanvasRenderingContext2D | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [color, setColor] = useState('black');
  const [size, setSize] = useState(5);
  const [showCanvas, setShowCanvas] = useState(false);
  const [previewImgUrl, setPreviewImgUrl] = useState(previewImg);

  useEffect(() => {
    if (!showCanvas) {
      return;
    }

    const image = new Image();
    const context = canvasElement.current!.getContext('2d');
    setCanvasContext(context);

    image.src = bgImg;
    image.onload = function () {
      canvasElement.current!.width = image.width;
      canvasElement.current!.height = image.height;

      context!.drawImage(image, 0, 0, image.width, image.height);
    };
  }, [bgImg, showCanvas]);

  function onMouseDown(e: MouseEvent<HTMLCanvasElement>) {
    canvasContext!.beginPath();
    canvasContext!.lineWidth = size;
    canvasContext!.strokeStyle = color;
    canvasContext!.lineJoin = 'round';
    canvasContext!.lineCap = 'round';
    canvasContext!.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    canvasContext!.stroke();
    setIsDrawing(true);

    document.addEventListener(
      'mouseup',
      () => {
        setIsDrawing(false);
        canvasContext!.closePath();
      },
      { once: true }
    );
  }

  function onMouseMove(e: MouseEvent<HTMLCanvasElement>) {
    if (isDrawing) {
      canvasContext!.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
      canvasContext!.stroke();
    }
  }

  return (
    <div>
      {!showCanvas && (
        <>
          <img
            src={previewImgUrl || bgImg}
            alt="preview img"
            crossOrigin="anonymous"
            width="auto"
            height="auto"
            className="mb-3"
          />

          <ActionIcon
              color="blue"
            variant="filled"
            onClick={() => setShowCanvas(true)}
          >
            <IconPencil />
          </ActionIcon>
        </>
      )}

      {showCanvas && (
        <div>
          <canvas
            ref={canvasElement}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            className="mb-1"
          ></canvas>

          <div className='w-[326]'>
            <div className="mb-3">
              <Slider
              size="xs"
                value={size}
                min={5}
                max={100}
                onChange={setSize}
              />
            </div>

            <ActionIcon
              variant="filled"
              color="blue"
              onClick={() => {
                const dataUrl = canvasElement.current?.toDataURL();
                onSave(dataUrl);
                setPreviewImgUrl(dataUrl);
                setShowCanvas(false);
              }}
            >
              <IconCheck />
            </ActionIcon>
          </div>
        </div>
      )}
      {/*
      <div className="flex gap-2">
        <InputRadio
          name="color"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setColor(e.target.value);
          }}
          checked={color === 'black'}
          value="black"
          label="Черный"
        />

        <InputRadio
          name="color"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setColor(e.target.value);
          }}
          checked={color === 'red'}
          value="red"
          label="Красный"
        />
      </div> */}
    </div>
  );
}
