import dayjs from 'dayjs';

export function convertStudyDate(inputDateStr, type = 'd/m/y') {
  if (type === 'd/m/y') {
    const year = inputDateStr.slice(0, 4);
    const month = inputDateStr.slice(4, 6);
    const day = inputDateStr.slice(6, 8);

    return `${day}/${month}/${year}`;
  }
}

export function formatDate(
  date?: string | Date | null,
  outputFormat = 'DD.MM.YYYY',
  inputFormat?: dayjs.OptionType
) {
  const obj = dayjs(date, inputFormat);
  return date && obj.isValid() ? obj.format(outputFormat) : '';
}
