import React, { useState } from 'react';
import { LegacyButton, Input, Select } from '@ohif/ui';
import { getEnabledElement } from '@cornerstonejs/core';
import { commandsManager } from '../../../../../platform/app/src/App';
import { useAppDispatch } from '../../../../../platform/app/src/store';
import { fetchDisease } from '../../../../../platform/app/src/store/reducers/DetectedDiseaseReducer';
import { Controller, useForm } from 'react-hook-form';
import { object, string, InferType } from 'yup';

const schema = object({
  frame: string().required(),
  catheter: string().required(),
  angle: object({ value: string().required() })
    .test('isNotNull', 'Cannot be null', v => v !== null)
    .notRequired(),
});

type AnalyzeFrameForm = InferType<typeof schema>;

const PanelDicomAnalyzer = ({}) => {
  const dispatch = useAppDispatch();
  const [isBestFrameLoading, setIsBestFrameLoading] = useState(false);
  const [isAnalyzeDataLoading, setAnalyzeDataLoading] = useState(false);

  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues: {
      frame: '',
      catheter: '2.3',
      angle: angleValues[0],
    },
  });

  const frame = watch('frame');

  function selectImage() {
    const activeViewportEnabledElement = getEnabledElement(
      document.querySelector('.cornerstone-viewport-element')
    );

    const { viewport } = activeViewportEnabledElement;
    setValue('frame', String(viewport.currentImageIdIndex));
  }

  async function autoSelectImage() {
    setIsBestFrameLoading(true);

    try {
      const { studyInstanceUid, seriesInstanceUid, instanceInstanceUid } =
        getCurrentUid();

      const response = await fetch(
        `${process.env.FRAME_EXTRACTOR_BACKEND}/best_frame_extractor?` +
          new URLSearchParams({
            file_name: `${studyInstanceUid},${seriesInstanceUid},${instanceInstanceUid}`,
          })
      );

      const bestFrame = await response.json();

      if (!bestFrame) {
        throw new Error();
      }

      setValue('frame', String(bestFrame));

      commandsManager.runCommand('jumpToSlice', {
        sliceIndex: bestFrame,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsBestFrameLoading(false);
    }
  }

  async function getAnalyzeData(data: AnalyzeFrameForm) {
    setAnalyzeDataLoading(true);

    const currentZoom = commandsManager.runCommand('getZoom');
    const { width, height } = commandsManager.runCommand(
      'getImageOriginalArea'
    );

    const cornerstoneCanvas = document.querySelector(
      '.cornerstone-canvas'
    ) as HTMLCanvasElement | null;

    const frameImageUrl = await cornerstoneCanvas?.toDataURL('image/png');

    const { studyInstanceUid, seriesInstanceUid, instanceInstanceUid } =
      getCurrentUid();

    const diseaseTypes = ['segment', 'stenosis', 'stenosisMetadata'];

    for (const diseaseType of diseaseTypes) {
      await dispatch(
        fetchDisease({
          type: diseaseType,
          studyInstanceUid,
          frameImageUrl,
          seriesInstanceUid,
          instanceInstanceUid,
          zoom: currentZoom,
          width,
          height,
          frame: data.frame,
          catheter: data.catheter,
          angle: data.angle.value,
        })
      );
    }

    setAnalyzeDataLoading(false);
    setValue('frame', '');
  }

  function getCurrentUid() {
    const { viewport } = getEnabledElement(
      document.querySelector('.cornerstone-viewport-element')
    );

    const imageId = viewport.getCurrentImageId();

    return {
      studyInstanceUid: imageId.match(/\/studies\/(.+)\/series/m)[1],
      seriesInstanceUid: imageId.match(/\/series\/(.+)\/instances/m)[1],
      instanceInstanceUid: imageId.match(/\/instances\/(.+)\/frames/m)[1],
    };
  }

  return (
    <form className="p-4" onSubmit={handleSubmit(getAnalyzeData)}>
      {frame === '' && (
        <div className="flex flex-col">
          <LegacyButton
            onClick={autoSelectImage}
            variant="outlined"
            border="light"
            isLoading={isBestFrameLoading}
          >
            Выбрать лучший снимок автоматически
          </LegacyButton>

          <LegacyButton
            className="mt-4"
            variant="outlined"
            border="light"
            onClick={selectImage}
            disabled={isBestFrameLoading}
          >
            Выбрать текущий снимок
          </LegacyButton>
        </div>
      )}

      {frame !== '' && (
        <div className="flex flex-col">
          <p className="text-white">Был выбран снимок номер {+frame + 1}</p>

          <LegacyButton
            onClick={() => setValue('frame', '')}
            variant="outlined"
            className="mt-2"
            border="light"
          >
            Выбрать другой снимок
          </LegacyButton>

          <Controller
            name="catheter"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                labelClassName="text-white mt-3"
                label="Введите размер катетера (Fr):"
              />
            )}
          />
          <div className="flex mt-3 flex-col">
            <label className="text-white">Выберите ракурс</label>
            <div className="flex-1 mt-2">
              <Controller
                name="angle"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      isClearable={false}
                      options={angleValues}
                      hideSelectedOptions={false}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      )}

      <LegacyButton
        variant="contained"
        color="light"
        border="light"
        disabled={frame === ''}
        className="mt-12"
        isLoading={isAnalyzeDataLoading}
        type="submit"
      >
        Провести анализ снимка
      </LegacyButton>
    </form>
  );
};

const angleValues = [
  { label: 'Автоматически', value: '0' },
  { label: 'Передняя Артерия', value: '1' },
  { label: 'Огибающая Артерия', value: '2' },
  { label: 'Правая Артерия', value: '3' },
  { label: 'Смешанная Артерия', value: '4' },
];
export default PanelDicomAnalyzer;
