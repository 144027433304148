import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { NewVisitFormType, serviceDefaultValues } from '../schema';
import moment from 'moment';
import { Table, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../../medreview/server/src';
import { notifications } from '@mantine/notifications';

interface Props {
  appointments: NonNullable<inferRouterOutputs<AppRouter>['visit']['getVisitApts']>;
  disabled: boolean;
  study_id?: number;
}

export default function NewVisitAppointments({ appointments, disabled = false, study_id }: Props) {
  const firstReadAppointments = appointments.filter(a => a.service_code === 'C03.082.005');
  const secondReadAppointments = appointments.filter(a => a.service_code === 'A02.074.000');
  const regularAppointments = appointments.filter(
    a => a.service_code !== 'C03.082.005' && a.service_code !== 'A02.074.000'
  );

  return (
    <>
      {firstReadAppointments.length !== 0 || secondReadAppointments.length !== 0 ? (
        <>
          <div className="mb-8">
            <p className="mb-2 font-bold">1 читка</p>
            <NewVisitAppointment
              appointments={firstReadAppointments}
              disabled={disabled}
              study_id={study_id}
            />
          </div>

          <div>
            <p className="mb-2 font-bold">2 читка</p>
            <NewVisitAppointment
              appointments={secondReadAppointments}
              disabled={disabled}
              study_id={study_id}
            />
          </div>
        </>
      ) : (
        <div>
          <NewVisitAppointment
            appointments={regularAppointments}
            disabled={disabled}
            study_id={study_id}
          />
        </div>
      )}
    </>
  );
}

function NewVisitAppointment({ appointments, disabled = false, study_id }: Props) {
  const { watch, setValue } = useFormContext<NewVisitFormType>();
  const services = watch('services');
  const { t } = useTranslation('Custom');

  return (
    <Table withBorder>
      <thead>
        <tr>
          <th>Дата создания направления</th>
          <th>{t('Appointment date')}</th>
          <th>{t('Organization')}</th>
          <th>{t('Service')}</th>
          <th>{t('Count')}</th>
          <th>{t('Appointment doctor')}</th>
          <th>{t('Visit date and time')}</th>
          <th>{t('Status')}</th>
        </tr>
      </thead>
      <tbody>
        {appointments.length > 0 ? (
          appointments.map(appointment => {
            const isActive = services?.find(sa => sa.appointment_id === appointment.id);
            const canBeSelected = [null, study_id].includes(appointment.study_id);

            return (
              <tr key={appointment.id}>
                <MobileWrapper title="Дата создания направления<">
                  {appointment.created_at
                    ? moment(appointment.created_at).format('DD.MM.YYYY HH:mm')
                    : ''}
                </MobileWrapper>
                <MobileWrapper title={t('Appointment date')}>
                  {appointment.appointment_date
                    ? moment(appointment.appointment_date).format('DD.MM.YYYY HH:mm')
                    : ''}
                </MobileWrapper>

                <MobileWrapper title={t('Organization')}>
                  {appointment.senderMoName || ''}
                </MobileWrapper>

                <MobileWrapper title={t('Service')}>{appointment.service_name || ''}</MobileWrapper>

                <MobileWrapper title={t('Count')}>{appointment.serviceCount || ''}</MobileWrapper>

                <MobileWrapper title={t('Appointment doctor')}>
                  {appointment.referrer || ''}
                </MobileWrapper>

                <MobileWrapper title={t('Visit date and time')}>
                  {appointment.recepton_date
                    ? moment(appointment.recepton_date).format('DD.MM.YYYY HH:mm')
                    : ''}
                </MobileWrapper>

                <MobileWrapper title={t('Status')}>
                  <Button
                    classNames={{
                      root: !isActive && !canBeSelected ? 'h-[4.25rem]' : '',
                      label: !isActive && !canBeSelected ? 'whitespace-normal' : '',
                    }}
                    onClick={() => {
                      if (disabled) {
                        notifications.show({
                          message:
                            'По завершенным обследованиям нельзя выбрать или открепить направление',
                          color: 'red',
                        });

                        return;
                      }

                      if (isActive) {
                        setValue(
                          'services',
                          services.length === 1
                            ? [serviceDefaultValues]
                            : services.filter(sa => sa.service_code !== appointment.service_code)
                        );

                        return;
                      }

                      if (!canBeSelected) {
                        notifications.show({
                          message: 'Данное направление было уже привязано на другое исследование.',
                          color: 'orange',
                        });
                      }

                      setValue('payment_type_slug', 'OSMS');

                      setValue('services', [
                        ...services.filter(
                          sa =>
                            sa.service_code !== '' && sa.service_code !== appointment.service_code
                        ),
                        {
                          id: Math.max(...services.map(s => s.id!)) + 1,
                          service_code: appointment.service_code || '',
                          service_count: appointment.serviceCount || 1,
                          comment: '',
                          appointment_id: appointment.id,
                        },
                      ]);
                    }}
                    variant={isActive ? 'filled' : 'outline'}
                  >
                    {isActive
                      ? t('Detach')
                      : canBeSelected
                        ? t('Select')
                        : t('Select for the current study')}
                  </Button>
                </MobileWrapper>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={3}>{t('Appointments are undefined')}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

function MobileWrapper({ title, children }: { title: string; children: ReactNode }) {
  return (
    <>
      <p className="inline-blick mt-1 mb-1 lg:hidden">
        <span className="font-bold">{title}</span>
        {children}
      </p>

      <td className="hidden lg:table-cell">{children}</td>
    </>
  );
}
