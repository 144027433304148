import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, InferType } from 'yup';
import { Button, TextInput, Loader } from '@mantine/core';
import CkeEditor from '../../../../../../../platform/components/CkeEditor/CkeEditor';
import {
  highlightSearchTerm,
  removeNegativeStyles,
} from '../../../../../../../platform/utils/stringUtils';
import useMediaQuery from '../../../../../../../platform/hooks/useMediaQuery';
import { trpc } from '../../../../../../../platform/app/trpc';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../../../../../medreview/server/src';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';
import { Badge } from '@mantine/core';

interface TemplatesSearchModalProps {
  onTemplateSelect: (
    template: NonNullable<inferRouterOutputs<AppRouter>['template']['get']>[number]
  ) => void;
}

const TemplatesSearchModal = ({ onTemplateSelect }: TemplatesSearchModalProps) => {
  const templateList = trpc.template.get.useQuery();
  const isMobile = useMediaQuery('(max-width: 639px)');
  const [templateToPreview, setTemplateToPreview] =
    useState<NonNullable<inferRouterOutputs<AppRouter>['template']['get']>[number]>();

  const { watch, register } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      templatesSearchVal: '',
    },
  });

  const templatesSearchVal = watch('templatesSearchVal');

  const filteredTemplateList = useMemo(() => {
    if (templateList.data?.length === 0 || !templatesSearchVal) {
      return templateList.data || [];
    }

    return templateList.data?.filter(template => {
      const regex = new RegExp(templatesSearchVal, 'i');

      return (
        regex.test(template.content) ||
        regex.test(template.tags || '') ||
        regex.test(template.template_name)
      );
    });
  }, [templatesSearchVal, templateList.data]);

  function showTemplatePreview(
    template: NonNullable<inferRouterOutputs<AppRouter>['template']['get']>[number]
  ) {
    if (isMobile) {
      selectTemplate(template);
      return;
    }

    setTemplateToPreview(template);
  }

  function selectTemplate(
    template: NonNullable<inferRouterOutputs<AppRouter>['template']['get']>[number]
  ) {
    onTemplateSelect(template);

    notifications.show({
      message: 'Шаблон скопирован',
      color: 'green',
    });

    modals.closeAll();
  }

  return (
    <div className="flex gap-x-6">
      <div className="w-full overflow-y-auto sm:w-1/2">
        <TextInput
          {...register('templatesSearchVal')}
          placeholder="Поиск"
        />

        <p className="mb-8 mt-4">Выполните поиск по названиям, тегам или тексту заключения</p>

        <div className="relative min-h-[140px]">
          {filteredTemplateList?.map(template => (
            <div
              key={template.id}
              onClick={() => showTemplatePreview(template)}
              className="mb-6 cursor-pointer rounded-md border p-1.5"
            >
              <h2
                className="text-xl"
                dangerouslySetInnerHTML={{
                  __html:
                    template.template_name &&
                    highlightSearchTerm(template.template_name, templatesSearchVal),
                }}
              ></h2>
              <div className="mt-3 flex gap-x-2">
                {template.tags?.split(',').map(tag => (
                  <Badge key={tag}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: tag && highlightSearchTerm(tag, templatesSearchVal),
                      }}
                    ></p>
                  </Badge>
                ))}
              </div>
              <div
                className="mt-4 max-h-[150px] overflow-y-hidden"
                dangerouslySetInnerHTML={{
                  __html:
                    template.content && templatesSearchVal
                      ? highlightSearchTerm(template.content, templatesSearchVal)
                      : template.content,
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="hidden w-1/2 sm:block">
        {templateToPreview && (
          <>
            <div className="mb-4 grid grid-cols-1 gap-4 px-2 sm:grid-cols-2">
              {[
                {
                  label: 'Результат R:',
                  value: `${templateToPreview.birads_right?.category} : ${templateToPreview.birads_right?.description}`,
                  isSelected: templateToPreview.birads_right,
                },
                {
                  label: 'Результат L:',
                  value: `${templateToPreview.birads_left?.category} : ${templateToPreview.birads_left?.description}`,
                  isSelected: templateToPreview.birads_left,
                },
                {
                  label: 'Локализация R:',
                  value: `${templateToPreview.localization_right?.ru}`,
                  isSelected: templateToPreview.localization_right,
                },
                {
                  label: 'Локализация L:',
                  value: `${templateToPreview.localization_left?.ru}`,
                  isSelected: templateToPreview.localization_left,
                },
              ].map(({ label, value, isSelected }) => {
                return isSelected ? (
                  <div key={label}>
                    <div className="mt-4 flex flex-col">
                      <p className="items-center whitespace-nowrap font-bold text-white">{label}</p>
                      <div className="flex-1">{value}</div>
                    </div>
                  </div>
                ) : (
                  <p></p>
                );
              })}
            </div>

            <CkeEditor
              data={removeNegativeStyles(templateToPreview.content, [
                'margin-left',
                'padding-left',
              ])}
              disabled={true}
            />

            <Button
              className="mt-4 w-full"
              onClick={() => selectTemplate(templateToPreview)}
            >
              Скопировать шаблон
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default TemplatesSearchModal;

export const schema = object({
  templatesSearchVal: string().required(),
});

export type TemplatesSearchForm = InferType<typeof schema>;
