import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, InferType, array } from 'yup';
import { Button, TextInput, MultiSelect } from '@mantine/core';
import moment from 'moment';
import { CnFirstMammoForm } from '../../CnMammo';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../../../../../medreview/server/src';
import { trpc } from '../../../../../../../platform/app/trpc';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';

interface Props {
  formData: CnFirstMammoForm;
  study?: NonNullable<inferRouterOutputs<AppRouter>['study']['get']>;
}

export default function SaveTemplateModal({ formData, study }: Props) {
  const modality_names = study?.modality_study.map(study => study.modalities.name);
  const [data, setData] = useState<{ value: string; label: string }[]>([]);

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    getValues,
  } = useForm<SaveTemplateForm>({
    resolver: yupResolver(schema),
    values: {
      templateName: `${modality_names?.[0] || ''}_${study?.description || ''}_${
        study?.date ? moment(study.date, 'YYYYMMDD').format('DD-MM-YYYY') : ''
      }`,
      templateTags: [],
    },
  });

  const createTemplate = trpc.template.create.useMutation({
    onSettled: (_, error) => {
      notifications.show({
        message: error ? 'Что-то пошло не так' : 'Шаблон сохранен',
        color: error ? 'red' : 'green',
      });

      modals.closeAll();
    },
  });

  return (
    <form
      onSubmit={handleSubmit(data => {
        createTemplate.mutate({
          template_name: data.templateName,
          content: formData.conclusion_text,
          conclusion_id: study?.conclusion[0]?.id || null,
          tags: data.templateTags.map(v => v.value),
          birads_left_id: Number(formData?.birads_code_left) || null,
          birads_right_id: Number(formData?.birads_code_right) || null,
          localization_left_id: Number(formData?.localization_left) || null,
          localization_right_id: Number(formData?.localization_right) || null,
        });
      })}
    >
      <div className="mt-4 flex">
        <div className="w-1/3">Название </div>
        <div className="ml-3 flex-1">
          <TextInput
            {...register('templateName')}
            error={errors.templateName?.message}
          />
        </div>
      </div>

      <div className="mt-4 flex">
        <div className="w-1/3">Теги</div>
        <div className="ml-3 flex-1">
          <MultiSelect
            creatable
            searchable
            getCreateLabel={query => `+ Добавить ${query}`}
            onCreate={query => {
              const item = { value: query, label: query };
              setData(current => [...current, item]);
              setValue('templateTags', [...getValues()['templateTags'], item]);
              return item;
            }}
            error={errors.templateTags?.message}
            data={data}
          />
        </div>
      </div>

      <div className="mt-4 flex">
        <div className="w-1/3 text-right"></div>
        <div className="ml-3 flex-1">
          <Button
            size="xs"
            type="submit"
            className="mr-3"
            loading={createTemplate.isLoading}
          >
            Сохранить шаблон
          </Button>
        </div>
      </div>
    </form>
  );
}

export const schema = object({
  templateName: string().required(),
  templateTags: array().required(),
});

export type SaveTemplateForm = InferType<typeof schema>;
