import React from 'react';
import { useFormContext } from 'react-hook-form';
import { NewVisitFormType } from '../../schema';
import { Checkbox } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export default function MammoVisitVisibleChanges() {
  const { register } = useFormContext<NewVisitFormType>();
  const { t } = useTranslation('Custom');

  const fieldBlocks = [
    {
      name: 'scar_mole_papilloma' as const,
      label: t('There is a scar, mole, papilloma on the skin, etc.'),
    },
    {
      name: 'nipple_changes' as const,
      label: t('Nipple changes'),
    },
    {
      name: 'nipple_discharge' as const,
      label: t('Nipple discharge'),
    },
    {
      name: 'deformation_compaction' as const,
      label: t('Deformation, compaction'),
    },
  ];

  return (
    <>
      <fieldset className="mt-12 border p-4">
        <legend className="px-4 font-bold">{t('Visible changes in the gland')}</legend>

        <div className="mt-4 flex flex-col gap-3 sm:flex-row">
          <div className="w-1/2"></div>
          <div className="flex w-1/2 gap-5">
            <p className="w-[20px] text-center">R</p>
            <p className="w-[20px] text-center">L</p>
          </div>
        </div>

        {fieldBlocks.map(fieldBlock => {
          return (
            <div
              className="mt-4 flex flex-col gap-3 sm:flex-row"
              key={fieldBlock.name}
            >
              <div className="w-1/2">{fieldBlock.label}</div>
              <div className="flex w-1/2 gap-5">
                <Checkbox {...register(`${fieldBlock.name}.right`)} />
                <Checkbox {...register(`${fieldBlock.name}.left`)} />
              </div>
            </div>
          );
        })}
      </fieldset>
    </>
  );
}
