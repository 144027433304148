export const xRayDensityGlandMap = {
  A: 'Практически полностью жировой тип строения',
  B: 'Рассеянные участки фиброгландулярной ткани',
  C: 'Гетерогенно плотные молочные железы. Плотные участки фиброгландулярной ткани могут скрывать мелкие образования',
  D: 'Очень плотные молочные железы. Чувствительность маммографии снижена',
};

export const mapMgQuality = {
  GOOD: 'Приемлемое качество',
  BAD: 'Плохое качество изображения',
  INCORRECT: 'Неправильная укладка',
};

export const biradsToRiskCancerMap = {
  M0: '-',
  M1: '0%',
  M2: '0%',
  M3: '0% < x ≤ 2%',
  M4A: '2% < x ≤ 10%',
  M4B: '10% < x ≤ 50%',
  M4C: '50% < x < 95%',
  M5: 'x ≥ 95%',
  M6: '-',
};

const defaultMouseBindings = {
  Primary: 'Zoom',
  Auxiliary: 'Pan',
  Secondary: 'WindowLevel',
};

export function getMouseBindings() {
  const storedMouseBindings = localStorage.getItem('userMouseBindings');
  return storedMouseBindings
    ? (JSON.parse(storedMouseBindings) as typeof defaultMouseBindings)
    : defaultMouseBindings;
}

export const mgPosMap = {
  'R-CC': 'L-CC',
  'L-CC': 'R-CC',
  'R-MLO': 'L-MLO',
  'L-MLO': 'R-MLO',
};

export const genderCodeToLabelMap = {
  '3': { value: 'MALE', label: 'Мужской' },
  '2': { value: 'FEMALE', label: 'Женский' },
} as const;
