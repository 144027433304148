import React, { ReactElement } from 'react';
import { Studies } from '../../types/StudyTypes';
import {
  IconCalendarClock,
  IconCloud,
  IconDownload,
  IconAlertCircle,
  IconNumber1Small,
  IconNumber2Small,
} from '@tabler/icons-react';
import { ThemeIcon, Menu, Tooltip, Text, Button, Modal, Badge, Flex } from '@mantine/core';
import { modals } from '@mantine/modals';
import NewVisitModal, { EditIinForm } from '../../../../components/NewVisitModal';
import { IconBrandWhatsapp } from '@tabler/icons-react';
import { trpc } from '../../../../app/trpc';
import { bytesToReadable } from '../../../../utils/numberUtils';
import { getOptimalDataSource } from '../../../../utils/orthancUtils';
import { downloadDisomArchive } from '../../../../hooks/useDownloadArchive';
import { IconRotate } from '@tabler/icons-react';
import { IconTrash } from '@tabler/icons-react';
import CancelStudyModal from '../../../../components/CancelStudyModal';
import { IconPencil } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import PanelConclusion from '../../../../../extensions/default/src/Panels/PanelConclusionNew';
import { dbStatusesMap } from '../DataSourceWrapper';
import { TooltipClipboard } from '@ohif/ui';
import { formatDate } from '../../../../utils/dateUtils';
import { checkIinBinSum } from '../../../../utils/checkIinBinSum';

export function IinStatusIcon({ study }: { study: Studies }) {
  return study.patients?.document_type === 'IIN' && !checkIinBinSum(study.patients?.iin) ? (
    <Tooltip label="Некорректный ИИН или тип документа. Нажмите, чтобы откорректировать">
      <ThemeIcon
        size="sm"
        color="red"
        onClick={e => {
          e.stopPropagation();
          modals.open({ children: <EditIinForm study={study} /> });
        }}
      >
        <IconAlertCircle stroke={3} />
      </ThemeIcon>
    </Tooltip>
  ) : null;
}

export function StudyStatusIcon({ study }: { study: Studies }) {
  const status = study.status as keyof typeof dbStatusesMap;

  const firstReadingCn = study?.conclusion.find(
    c => c.type === 'FIRST_READING' || c.type === 'FIRST_READING_PAID'
  );
  const secondReadingCn = study?.conclusion.find(
    c => c.type === 'SECOND_READING' || c.type === 'SECOND_READING_PAID'
  );

  if (!study.is_mammo || status === 'CANCELLED' || status === 'CREATED') {
    return (
      <Flex className="flex-col items-center justify-center">
        <Badge
          color={dbStatusesMap[status].color}
          variant="filled"
        >
          {dbStatusesMap[status].label}
        </Badge>
        {study.conclusion.map(cn => (
          <Tooltip
            key={cn.id}
            label="Дата и время заключения врача"
          >
            <Text>{formatDate(cn.created_at, 'DD.MM.YYYY, HH:mm')}</Text>
          </Tooltip>
        ))}
      </Flex>
    );
  }

  if (study.is_mammo) {
    return (
      <Flex className="items-center justify-center gap-2">
        <Tooltip
          label={
            firstReadingCn
              ? `1 читка завершена (${formatDate(firstReadingCn.created_at, 'DD.MM.YYYY, HH:mm')})`
              : '1 читка в процессе'
          }
        >
          <Badge
            color={firstReadingCn ? 'green' : 'orange'}
            variant="filled"
          >
            <IconNumber1Small />
          </Badge>
        </Tooltip>

        <Tooltip
          label={
            secondReadingCn
              ? `2 читка завершена (${formatDate(secondReadingCn.created_at, 'DD.MM.YYYY, HH:mm')})`
              : '2 читка в процессе'
          }
        >
          <Badge
            color={secondReadingCn ? 'green' : 'orange'}
            variant="filled"
          >
            <IconNumber2Small />
          </Badge>
        </Tooltip>
      </Flex>
    );
  }

  return null;
}

export function CloudStatus({ study }: { study: Studies }) {
  return (
    <div>
      <Tooltip
        label={
          study.uploaded_to_the_cloud
            ? 'Обследование загружено на облачный сервер'
            : 'Обследование еще не загружено на облачный сервер'
        }
      >
        <ThemeIcon
          color={study.uploaded_to_the_cloud ? 'green' : 'orange'}
          variant="filled"
          className="p-[2px]"
        >
          <IconCloud />
        </ThemeIcon>
      </Tooltip>
    </div>
  );
}

export function EditStudyIcon({ study }: { study: Studies }) {
  const hasIncompletePatientData =
    ['ERROR', 'PhoneNotFound', 'PhonеNotFound'].includes(study.SMS_status || '') ||
    study.incorrectIIN ||
    !study.patients?.fullname ||
    !study.patients?.bdate;

  return (
    <div
      onClick={() => {
        modals.open({ children: <NewVisitModal study={study} />, size: 1050 });
      }}
    >
      <Tooltip
        label={
          hasIncompletePatientData ? (
            <Text>
              Изменить детали исследования и пациента. <br />
              Некоторые данные о пациенте отсутствуют или некорректны
            </Text>
          ) : (
            'Изменить детали исследования и пациента'
          )
        }
      >
        <ThemeIcon
          color={hasIncompletePatientData ? 'red' : 'green'}
          variant="filled"
          className="p-[2px]"
        >
          <IconPencil />
        </ThemeIcon>
      </Tooltip>
    </div>
  );
}

export function SmsStatusIcons({ study }: { study: Studies }) {
  let color = '';
  let label: ReactElement | string = '';

  if (study.patients?.phone) {
    color = 'blue';
    label = (
      <Text>
        Номер пациента найден. Ожидается отправка <br />
        Whatsapp сообщения {!study.conclusion[0] ? ' после формирования заключения' : ''}
      </Text>
    );
  }
  if (!study.patients?.phone) {
    color = 'red';
    label = 'Некорректный номер телефона';
  }
  if (study.SMS_status === 'PENDING' || study.SMS_status === 'SUCCESS') {
    color = 'green';
    label = 'Успешно отправлено Whatsapp сообщение';
  }
  if (study.SMS_status === 'ERROR') {
    color = 'red';
    label = (
      <Text>
        Ошибка при отправке Whatsapp сообщения. <br /> Возможно, некорректный номер телефона
      </Text>
    );
  }

  if (!color || !label) {
    return null;
  }

  return (
    <div>
      <Tooltip label={label}>
        <ThemeIcon
          color={color}
          variant="filled"
          className="p-[2px]"
        >
          <IconBrandWhatsapp />
        </ThemeIcon>
      </Tooltip>
    </div>
  );
}

export function AppointmentsIcon({ study }: { study: Studies }) {
  let color = '';
  let message = '';

  if (
    study.mammo_questionnaire[0]?.closed_on_damumed ||
    study.appointment[0]?.status === 'SENT_TO_SYSTEM'
  ) {
    color = 'green';
    message = 'Направление найдено, результат успешно отправлен в Damumed.';
  } else if (
    study.appointment[0]?.status === 'SENT_TO_API' ||
    study.appointment[0]?.status === 'LOADED_FROM_API'
  ) {
    color = 'orange';
    message = 'Направление найдено, ожидается отправка результата.';
  } else if (!study.appointment[0]) {
    color = 'gray';
    message = 'Направление не найдено, возможно пациент прошел исследование на платной основе.';
  }

  if (!color || !message) {
    return null;
  }

  return (
    <div>
      <Tooltip label={message}>
        <ThemeIcon
          color={color}
          variant="filled"
          className="p-[2px]"
        >
          <IconCalendarClock />
        </ThemeIcon>
      </Tooltip>
    </div>
  );
}

export function DownloadConclusionIcon({ study }: { study: Studies }) {
  const user = trpc.general.getUser.useQuery();

  return (
    <Menu withArrow>
      <Menu.Target>
        <Tooltip label="Скачать">
          <ThemeIcon
            variant="filled"
            className="p-[2px]"
          >
            <IconDownload />
          </ThemeIcon>
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown>
        {study.uploaded_to_the_cloud && (
          <Menu.Item
            onClick={async () => {
              const optimalDataSource = await getOptimalDataSource(
                study.organizations!.orthanc_config[0] || null,
                user.data?.organization_user || [],
                study.ohif_id
              );
              downloadDisomArchive(study.ohif_id, optimalDataSource);
            }}
          >
            DICOM-архив исследования{' '}
            {study.archive_size ? `(${bytesToReadable(study.archive_size)})` : ''}
          </Menu.Item>
        )}
        {study.conclusion
          .filter(c => c.conclusion_url)
          .map((c, i) => (
            <Menu.Item key={c.conclusion_url}>
              <a
                href={`${process.env.S3_URL + '/' + c.conclusion_url}`}
                target="_blank"
                rel="noreferrer"
              >
                <div>Заключение {i + 1}</div>
              </a>
            </Menu.Item>
          ))}
      </Menu.Dropdown>
    </Menu>
  );
}

export function CancelStudyIcon({ study }: { study: Studies }) {
  return (
    <div
      onClick={() => {
        modals.open({
          children: (
            <CancelStudyModal
              id={study.id}
              status={study.status || ''}
            />
          ),
        });
      }}
    >
      <Tooltip
        label={
          study.status === 'CANCELLED' ? 'Вернуть отмененное обследование' : 'Отменить обследование'
        }
      >
        <ThemeIcon
          color={study.status === 'CANCELLED' ? 'green' : 'red'}
          variant="filled"
          className="p-[2px]"
        >
          {study.status === 'CANCELLED' ? <IconRotate /> : <IconTrash />}
        </ThemeIcon>
      </Tooltip>
    </div>
  );
}

export function ServicesIcon({ study }: { study: Studies }) {
  const shouldCloseOnDamumed =
    (study.appointment.some(a => a.organization_id !== study.organization_id) && !study.is_mammo) ||
    (study.payment_type?.payment_type === 'OSMS' && study.appointment.length === 0);

  return (
    <div className="flex flex-wrap items-center gap-1 whitespace-normal">
      {study.services?.length > 0 && (
        <div className="flex flex-col gap-3">
          {study.services.map(service => {
            return (
              <p key={service.id}>
                <TooltipClipboard>
                  {service.price.services?.short_service_name ||
                    service.price.services?.service_name ||
                    ''}
                </TooltipClipboard>
              </p>
            );
          })}
        </div>
      )}

      <Tooltip
        label="Редактировать"
        onClick={e => e.stopPropagation()}
      >
        <ThemeIcon
          size="sm"
          color="blue"
          onClick={e => {
            e.stopPropagation();
            modals.open({
              children: (
                <NewVisitModal
                  study={study}
                  showPartial="services"
                />
              ),
              size: 1050,
            });
          }}
        >
          <IconPencil />
        </ThemeIcon>
      </Tooltip>

      {shouldCloseOnDamumed && (
        <Tooltip label="Данное направление следует закрывать вручную в Дамумед">
          <ThemeIcon
            size="sm"
            color="red"
          >
            <IconAlertCircle stroke={3} />
          </ThemeIcon>
        </Tooltip>
      )}
    </div>
  );
}

export function RewriteResponsibleUserBtn({ study }: { study: Studies }) {
  const [opened, { open, close }] = useDisclosure(false);
  const trpcUtils = trpc.useUtils();

  const rewriteResponsibleUser = trpc.study.rewriteResponsibleUser.useMutation({
    onSuccess: () => {
      window.open(`viewer?StudyInstanceUIDs=${study.ohif_id}`, '_blank');
      trpcUtils.study.getAll.invalidate();
    },
  });

  const user = trpc.general.getUser.useQuery();

  const canTakeWork =
    user.data?.canMakeConclusions &&
    (study.status === 'IN_PROGRESS' || (study.status === 'CREATED' && !study.is_mammo));

  const canBeMainDoctor = !study.responsible_user && !user.data?.roleHelper.isFirstReader;
  const canBeFirstReader =
    !study.first_reader_responsible_user && user.data?.roleHelper.isFirstReader;

  return (
    <div>
      <div className="flex flex-col gap-2">
        <p>
          <TooltipClipboard>{study.first_reader_responsible_user?.fullname || ''}</TooltipClipboard>
        </p>
        <p>
          <TooltipClipboard>{study.responsible_user?.fullname || ''}</TooltipClipboard>
        </p>
      </div>

      {canTakeWork && (canBeMainDoctor || canBeFirstReader) && (
        <Button
          size="xs"
          loading={rewriteResponsibleUser.isLoading}
          onClick={e => {
            e.stopPropagation();
            study.ohif_id || study.is_mammo
              ? rewriteResponsibleUser.mutate({
                  studyId: study.id,
                  userId: user.data!.id,
                })
              : open();
          }}
        >
          Взять в работу
        </Button>
      )}

      <div onClick={e => e.stopPropagation()}>
        <Modal
          opened={opened}
          onClose={close}
          size={1050}
        >
          <PanelConclusion
            studyId={study.id}
            onSave={() => {
              trpcUtils.study.getAll.invalidate();
            }}
          />
        </Modal>
      </div>
    </div>
  );
}
