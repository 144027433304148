import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { Select, TextInput } from '@mantine/core';
import { CnFirstMammoForm } from '../CnMammo';
import { xRayDensityGlandMap } from '../../../../../../platform/constants';

export default function CnMammoXRay({ disabled }: { disabled: boolean }) {
  const {
    formState: { errors },
    control,
    watch,
    register,
  } = useFormContext<CnFirstMammoForm>();

  const mg_quality = watch('mg_quality');

  return (
    <div className="mt-4">
      <TextInput
        {...register('entranceDoseInmGy')}
        label="Эффективная эквивалентная доза (мЗв)"
        className="mb-3"
        type="number"
        readOnly={disabled}
        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
      />

      <Controller
        name="x_ray_density_gland"
        control={control}
        render={({ field }) => {
          return (
            <Select
              {...field}
              label="Рентгенплотность железы"
              data={[
                {
                  value: 'A',
                  label: `A: ${xRayDensityGlandMap.A}`,
                },
                {
                  value: 'B',
                  label: `B: ${xRayDensityGlandMap.B}`,
                },
                {
                  value: 'C',
                  label: `C: ${xRayDensityGlandMap.C}`,
                },
                {
                  value: 'D',
                  label: `D: ${xRayDensityGlandMap.D}`,
                },
              ]}
              error={errors.x_ray_density_gland?.message}
              readOnly={disabled || mg_quality.right !== 'GOOD'}
              className="mb-3"
            />
          );
        }}
      />

      {fieldBlocks.map(fieldBlock => {
        return (
          <div
            className="mb-3 flex items-center gap-4"
            key={fieldBlock.name}
          >
            <div className="w-1/2">
              <Controller
                name={`${fieldBlock.name}.right`}
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      label={`${fieldBlock.label} R:`}
                      data={fieldBlock.options}
                      error={errors[fieldBlock.name]?.right?.message}
                      readOnly={
                        disabled ||
                        (fieldBlock.name !== 'mg_quality' && mg_quality.right !== 'GOOD')
                      }
                    />
                  );
                }}
              />
            </div>
            <div className="w-1/2">
              <Controller
                name={`${fieldBlock.name}.left`}
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      label={`${fieldBlock.label} L:`}
                      data={fieldBlock.options}
                      error={errors[fieldBlock.name]?.left?.message}
                      readOnly={
                        disabled || (fieldBlock.name !== 'mg_quality' && mg_quality.left !== 'GOOD')
                      }
                    />
                  );
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

const fieldBlocks = [
  {
    name: 'mg_quality' as const,
    label: 'Качество МГ',
    options: [
      {
        value: 'GOOD' as const,
        label: 'Приемлемое качество',
      },
      {
        value: 'BAD' as const,
        label: 'Плохое качество изображения',
      },
      {
        value: 'INCORRECT' as const,
        label: 'Неправильная укладка',
      },
    ],
  },
];
