import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CnMammoForm } from '../../CnMammo';
import SaveTemplateModal from '../template/SaveTemplateModal';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../../../../../medreview/server/src';
import { trpc } from '../../../../../../../platform/app/trpc';
import { modals } from '@mantine/modals';
import { Tooltip, ActionIcon } from '@mantine/core';
import { IconDeviceFloppy } from '@tabler/icons-react';

export default function SaveTemplateBtn({
  study,
}: {
  study?: inferRouterOutputs<AppRouter>['study']['get'] | null;
}) {
  const { getValues } = useFormContext<CnMammoForm>();
  const user = trpc.general.getUser.useQuery();

  function handleTemplateSaveModalOpen() {
    modals.open({
      children: (
        <SaveTemplateModal
          formData={getValues() as any}
          study={study!}
        />
      ),
    });
  }

  return (
    <>
      {user.data?.canMakeConclusions && (
        <ActionIcon
          variant="filled"
          onClick={handleTemplateSaveModalOpen}
          className="p-[2px]"
        >
          <Tooltip label="Сохранить шаблон">
            <IconDeviceFloppy />
          </Tooltip>
        </ActionIcon>
      )}
    </>
  );
}
