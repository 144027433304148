import React from 'react';
import CkeEditor from '../../../../../../platform/components/CkeEditor/CkeEditor';
import { useFormContext } from 'react-hook-form';
import { CnMammoForm } from '../CnMammo';
import TemplateSearchBtn from './btns/TemplateSearchBtn';

export default function CnEditor({ disabled }: { disabled: boolean }) {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<CnMammoForm>();

  const conclusionText = watch('conclusion_text');

  return (
    <div className="mt-7">
      {!disabled && (
        <div className="mb-2 flex justify-end">
          <TemplateSearchBtn />
        </div>
      )}

      <CkeEditor
        data={conclusionText}
        disabled={disabled}
        onChange={(v: string) => setValue('conclusion_text', v)}
      />

      {errors.conclusion_text?.message && (
        <span className="text-md text-red-500">{errors.conclusion_text?.message}</span>
      )}
    </div>
  );
}
