import React from 'react';
import { useFormContext } from 'react-hook-form';
import { removeTagsAndCopy } from '../../../../../../../platform/utils/stringUtils';
import { DefaultForm } from '../../CnDefault';
import { notifications } from '@mantine/notifications';
import { Tooltip, ActionIcon } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';

export default function CopyTextBtn() {
  const { getValues } = useFormContext<DefaultForm>();

  function copyCnText() {
    removeTagsAndCopy(getValues()['conclusion_text']);

    notifications.show({
      message: 'Текст заключения скопирован',
      color: 'green',
    });
  }

  return (
    <ActionIcon
      variant="filled"
      onClick={copyCnText}
      className="p-[2px]"
    >
      <Tooltip label="Скопировать текст заключения">
        <IconCopy />
      </Tooltip>
    </ActionIcon>
  );
}
