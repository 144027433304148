import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CnFirstMammoForm } from '../../CnMammo';
import { Button } from '@mantine/core';
import TemplatesSearchModal from '../template/TemplatesSearchModal';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../../../../../medreview/server/src';
import { modals } from '@mantine/modals';

export default function TemplateSearchBtn() {
  const { setValue } = useFormContext<CnFirstMammoForm>();

  function openModal() {
    modals.open({
      children: (
        <TemplatesSearchModal
          onTemplateSelect={(
            t: NonNullable<inferRouterOutputs<AppRouter>['template']['get']>[number]
          ) => {
            setValue('conclusion_text', t.content);
            setValue('birads_code_left', String(t.birads_left_id || ''));
            setValue('birads_code_right', String(t.birads_right_id || ''));
            setValue('localization_left', String(t.localization_left_id || ''));
            setValue('localization_right', String(t.localization_right_id || ''));
          }}
        />
      ),
      size: 1400,
    });
  }

  return (
    <Button
      size="xs"
      onClick={openModal}
    >
      Выбрать из шаблона
    </Button>
  );
}
