import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NewVisitMainData from './components/NewVisitMainData';
import NewVisitAppointments from './components/NewVisitAppointments';
import NewVisitServiceData from './components/NewVisitServiceData';
import NewVisitAnamnesisData from './components/NewVisitAnamnesisData';
import NewVisitPaymentTypeData from './components/NewVisitPaymentTypeData';
import NewVisitModalitySlugsData from './components/NewVisitModalitySlugsData';
import NewVisitDoctorData from './components/NewVisitDoctorData';
import {
  schema,
  serviceModalities,
  serviceDefaultValues,
  NewVisitFormType,
  getBdate,
  hasDisease,
  extractServiceTypeByModalities,
  getStringBool,
  documentTypes,
} from './schema';
import useDebounce from '../../hooks/useDebounce';
import NewVisitTreatmentReason from './components/NewVisitTreatmentReason';
import MammoVisitMainData from './components/mammo/MammoVisitMainData';
import MammoVisitAnamnesis from './components/mammo/MammoVisitAnamnesis';
import MammoVisitAssistant from './components/mammo/MammoVisitAssistant';
import MammoVisitVisibleChanges from './components/mammo/MammoVisitVisibleChanges';
import MammoVisitFirstReader from './components/mammo/MammoVisitFirstReader';
import MammoVisitSecondReader from './components/mammo/MammoVisitSecondReader';
import { checkIinBinSum } from '../../utils/checkIinBinSum';
import { Skeleton, Button, Input, Radio, TextInput, Box, Text, Flex } from '@mantine/core';
import { modals } from '@mantine/modals';
import dayjs from 'dayjs';
import { Studies } from '../../app/src/types/StudyTypes';
import { useTranslation } from 'react-i18next';
import { trpc } from '../../app/trpc';
import { notifications } from '@mantine/notifications';
import { object, string, InferType } from 'yup';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../medreview/server/src';
import { IconArrowDown } from '@tabler/icons-react';

interface Props {
  study?: Studies;
  showPartial?: 'mammo' | 'services';
  onClose?: () => void;
}

export default function NewVisitModal({ study, showPartial, onClose }: Props) {
  const trpcUtils = trpc.useUtils();
  const user = trpc.general.getUser.useQuery();
  const mammoQ = study?.mammo_questionnaire[0];
  const { t } = useTranslation('Custom');
  const [mainStepCompleted, setMainStepCompleted] = useState(!!study);

  const f = useForm<NewVisitFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      orgId: String(study?.organization_id || user.data!.organization_user[0]!.organization_id!),
      serviceModality: study
        ? extractServiceTypeByModalities(study.modality_study.map(s => s.modalities.name!))
        : undefined,
      document_type: study?.patients?.document_type || 'IIN',
      iin: study?.patients?.iin || '',
      fullname: '',
      bdate: undefined,
      phone: '',
      email: '',

      payment_type_slug: '',
      insurance_organizations_id: study?.insurance_organizations_id?.toString() || '',
      services: [serviceDefaultValues],

      treatment_reason_code: '',
      complaints: '',
      medical_history: '',

      has_postponed_operations: '',
      has_metalforeign_bodies: '',
      has_electronic_devices: '',

      postponed_operations: '',
      metalforeign_bodies: '',
      electronic_devices: '',

      first_reader_responsible_user_id: study?.first_reader_responsible_user_id?.toString() || '',
      responsible_user_id: study?.responsible_user_id?.toString() || '',
      physician_user_id: study?.physician_user_id?.toString() || '',

      // mammo *******
      visit_type: mammoQ?.visit_type || undefined,
      is_first_mammo:
        mammoQ?.is_first_mammo === true ? 'yes' : mammoQ?.is_first_mammo === false ? 'no' : '',
      is_first_screening:
        mammoQ?.is_first_screening === true
          ? 'yes'
          : mammoQ?.is_first_screening === false
            ? 'no'
            : '',
      has_other_mg_studies:
        mammoQ?.has_other_mg_studies === true
          ? 'yes'
          : mammoQ?.has_other_mg_studies === false
            ? 'no'
            : '',
      has_prev_mg_studies:
        mammoQ?.has_prev_mg_studies === true
          ? 'yes'
          : mammoQ?.has_prev_mg_studies === false
            ? 'no'
            : '',
      has_menopause:
        mammoQ?.has_menopause === true ? 'yes' : mammoQ?.has_menopause === false ? 'no' : '',
      heredity: mammoQ?.heredity === true ? 'yes' : mammoQ?.heredity === false ? 'no' : '',
      hormone_replacement_therapy:
        mammoQ?.hormone_replacement_therapy === true
          ? 'yes'
          : mammoQ?.hormone_replacement_therapy === false
            ? 'no'
            : '',
      has_complaints:
        mammoQ?.has_complaints === true ? 'yes' : mammoQ?.has_complaints === false ? 'no' : '',

      first_screening_data: mammoQ?.first_screening_data || '',
      other_mg_studies_data: mammoQ?.other_mg_studies_data || '',
      menopause_age: mammoQ?.menopause_age || '',
      breast_cancer: mammoQ?.breast_cancer || '',
      breast_cancer_other: mammoQ?.breast_cancer_other || '',
      complaints_data: mammoQ?.complaints_data || '',
      mammo_total_number: mammoQ?.mammo_total_number?.toString() || '',

      assistant_note: mammoQ?.assistant_note || '',
      assistant_date: new Date(mammoQ?.assistant_date || study?.created_at || Date.now()),
      closed_on_damumed: mammoQ?.closed_on_damumed || false,

      // TODO2: should be date or null
      cyst_aspiration: {
        right: mammoQ?.cyst_aspiration?.right
          ? dayjs(mammoQ?.cyst_aspiration?.right).toDate()
          : undefined,
        left: mammoQ?.cyst_aspiration?.left
          ? dayjs(mammoQ?.cyst_aspiration?.left).toDate()
          : undefined,
      },
      sector_resection: {
        right: mammoQ?.sector_resection?.right
          ? dayjs(mammoQ?.sector_resection?.right).toDate()
          : undefined,
        left: mammoQ?.sector_resection?.left
          ? dayjs(mammoQ?.sector_resection?.left).toDate()
          : undefined,
      },
      mastectomy: {
        right: mammoQ?.mastectomy?.right ? dayjs(mammoQ?.mastectomy?.right).toDate() : undefined,
        left: mammoQ?.mastectomy?.left ? dayjs(mammoQ?.mastectomy?.left).toDate() : undefined,
      },
      organ_reservation_operation: {
        right: mammoQ?.organ_reservation_operation?.right
          ? dayjs(mammoQ?.organ_reservation_operation?.right).toDate()
          : undefined,
        left: mammoQ?.organ_reservation_operation?.left
          ? dayjs(mammoQ?.organ_reservation_operation?.left).toDate()
          : undefined,
      },
      radiation_therapy: {
        right: mammoQ?.radiation_therapy?.right
          ? dayjs(mammoQ?.radiation_therapy?.right).toDate()
          : undefined,
        left: mammoQ?.radiation_therapy?.left
          ? dayjs(mammoQ?.radiation_therapy?.left).toDate()
          : undefined,
      },
      other_operations: {
        right: mammoQ?.other_operations?.right
          ? dayjs(mammoQ?.other_operations?.right).toDate()
          : undefined,
        left: mammoQ?.other_operations?.left
          ? dayjs(mammoQ?.other_operations?.left).toDate()
          : undefined,
      },

      scar_mole_papilloma: {
        right: mammoQ?.scar_mole_papilloma?.right || false,
        left: mammoQ?.scar_mole_papilloma?.left || false,
      },
      nipple_changes: {
        right: mammoQ?.nipple_changes?.right || false,
        left: mammoQ?.nipple_changes?.left || false,
      },
      nipple_discharge: {
        right: mammoQ?.nipple_discharge?.right || false,
        left: mammoQ?.nipple_discharge?.left || false,
      },
      deformation_compaction: {
        right: mammoQ?.deformation_compaction?.right || false,
        left: mammoQ?.deformation_compaction?.left || false,
      },

      direct_projection: {
        right: mammoQ?.direct_projection?.right?.toString() || '',
        left: mammoQ?.direct_projection?.left?.toString() || '',
      },
      oblique_projection: {
        right: mammoQ?.oblique_projection?.right?.toString() || '',
        left: mammoQ?.oblique_projection?.left?.toString() || '',
      },
    },
    mode: 'onChange',
  });

  const document_type = f.watch('document_type');
  const iin = f.watch('iin');
  const debouncedIin = useDebounce<string>(iin || '', 1000);
  const payment_type_slug = f.watch('payment_type_slug');
  const serviceModality = f.watch('serviceModality');
  const currentOrgId = f.watch('orgId');

  const visitQueryProps = {
    enabled: mainStepCompleted,
    cacheTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
  };

  const visitPatient = trpc.visit.getVisitPatientData.useQuery(
    { documentType: document_type, iin: document_type === 'IIN' ? iin : debouncedIin },
    visitQueryProps
  );

  const visitApts = trpc.visit.getVisitApts.useQuery(
    {
      iin: document_type === 'IIN' ? iin : debouncedIin,
      modalities: serviceModalities[serviceModality]?.modality_slugs as any,
      studyId: study?.id,
      orgId: +currentOrgId,
    },
    visitQueryProps
  );

  useEffect(() => {
    if (!visitPatient.isSuccess) {
      return;
    }

    const anamnesis = study?.anamesis[0] || visitPatient.data?.anamesis[0];
    const bdate = getBdate(
      visitPatient.data?.bdate,
      document_type === 'IIN' ? visitPatient.data?.iin : null
    );

    f.setValue('fullname', visitPatient.data?.fullname || '');
    f.setValue('bdate', bdate!);
    f.setValue('phone', visitPatient.data?.phone || '');
    f.setValue('email', visitPatient.data?.email || '');
    f.setValue('complaints', anamnesis?.complaints || '');
    f.setValue('medical_history', anamnesis?.medical_history || '');
    f.setValue('has_postponed_operations', hasDisease('postponed_operations', anamnesis));
    f.setValue('has_metalforeign_bodies', hasDisease('metalforeign_bodies', anamnesis));
    f.setValue('has_electronic_devices', hasDisease('electronic_devices', anamnesis));
    f.setValue('postponed_operations', anamnesis?.postponed_operations || '');
    f.setValue('metalforeign_bodies', anamnesis?.metalforeign_bodies || '');
    f.setValue('electronic_devices', anamnesis?.electronic_devices || '');

    study && showPartial !== 'mammo' && f.trigger();
  }, [visitPatient.data, f, document_type, visitPatient.isSuccess, study, showPartial]);

  useEffect(() => {
    if (!visitApts.isSuccess) {
      return;
    }
    f.setValue(
      'payment_type_slug',
      study ? study.payment_type?.payment_type : visitApts.data.length === 0 ? 'PRIVATELY' : 'OSMS'
    );
  }, [visitApts.data, showPartial, study, f, visitApts.isSuccess]);

  useEffect(() => {
    if (!visitPatient.isSuccess || !visitApts.isSuccess) {
      return;
    }

    const anamnesis = study?.anamesis[0] || visitPatient.data?.anamesis[0];
    const treatmentReasonCode = anamnesis?.treatment_reason_code?.toString();

    f.setValue(
      'treatment_reason_code',
      treatmentReasonCode
        ? treatmentReasonCode
        : visitApts.data?.find(a => a.service_code === 'C03.082.005')
          ? '17'
          : ''
    );

    study && showPartial !== 'mammo' && f.trigger();
  }, [
    visitApts.data,
    visitPatient.data,
    showPartial,
    study,
    f,
    visitPatient.isSuccess,
    visitApts.isSuccess,
  ]);

  const upsertVisit = trpc.visit.upsertVisit.useMutation({
    onSettled: (_, error) => {
      notifications.show({
        message: error ? 'Что-то пошло не так' : 'Данные сохранены',
        color: error ? 'red' : 'green',
      });

      if (onClose) {
        onClose();
      } else {
        modals.closeAll();
        trpcUtils.study.getAll.invalidate();
        trpcUtils.study.get.invalidate();
      }
    },
  });

  async function onSubmit() {
    if (!(study ? true : await f.trigger())) {
      return;
    }

    const data = f.getValues();

    upsertVisit.mutate({
      orgId: +data.orgId,
      studyId: study?.id || null,
      modality_slugs: (!study ? serviceModalities[serviceModality]?.modality_slugs : []) as any,
      document_type: data.document_type,
      iin: data.iin,
      fullname: data.fullname || null,
      bdate: data.bdate ? dayjs(data.bdate).format('YYYY-MM-DD') : null,
      phone: data.phone || null,
      email: data.email || null,
      payment_type_slug: data.payment_type_slug || null,
      insurance_organizations_id: data.insurance_organizations_id
        ? +data.insurance_organizations_id
        : null,
      services: data.services
        .filter(ss => ss.service_code)
        .map(s => {
          return {
            service_code: s.service_code!,
            service_count: s.service_count || 1,
            comment: s.comment || null,
            appointment_id: s.appointment_id || null,
          };
        }),
      first_reader_responsible_user_id: data.first_reader_responsible_user_id
        ? +data.first_reader_responsible_user_id
        : null,
      responsible_user_id: data.responsible_user_id ? +data.responsible_user_id : null,

      physician_user_id:
        !study && user.data?.roleHelper.isAssistant
          ? user.data!.id
          : data.physician_user_id
            ? +data.physician_user_id
            : null,
      anamesis: {
        treatment_reason_code: data.treatment_reason_code ? +data.treatment_reason_code : null,
        complaints: data.complaints || null,
        medical_history: data.medical_history || null,
        postponed_operations: data.postponed_operations || null,
        metalforeign_bodies: data.metalforeign_bodies || null,
        electronic_devices: data.electronic_devices || null,
      },
      mammo_questionnaire:
        data.serviceModality === 'MG'
          ? {
              is_first_mammo: getStringBool(data.is_first_mammo),
              is_first_screening: getStringBool(data.is_first_screening),
              first_screening_data: data.first_screening_data || null,
              has_other_mg_studies: getStringBool(data.has_other_mg_studies),
              other_mg_studies_data: data.other_mg_studies_data || null,
              has_prev_mg_studies: getStringBool(data.has_prev_mg_studies),
              has_menopause: getStringBool(data.has_menopause),
              menopause_age: data.menopause_age || null,
              heredity: getStringBool(data.heredity),
              breast_cancer: data.breast_cancer || null,
              breast_cancer_other: data.breast_cancer_other || null,
              hormone_replacement_therapy: getStringBool(data.hormone_replacement_therapy),
              has_complaints: getStringBool(data.has_complaints),
              complaints_data: data.complaints_data || null,

              cyst_aspiration: {
                right: data.cyst_aspiration?.right
                  ? dayjs(data.cyst_aspiration?.right).format('YYYY-MM-DD')
                  : null,
                left: data.cyst_aspiration?.left
                  ? dayjs(data.cyst_aspiration?.left).format('YYYY-MM-DD')
                  : null,
              },
              sector_resection: {
                right: data.sector_resection?.right
                  ? dayjs(data.sector_resection?.right).format('YYYY-MM-DD')
                  : null,
                left: data.sector_resection?.left
                  ? dayjs(data.sector_resection?.left).format('YYYY-MM-DD')
                  : null,
              },
              mastectomy: {
                right: data.mastectomy?.right
                  ? dayjs(data.mastectomy?.right).format('YYYY-MM-DD')
                  : null,
                left: data.mastectomy?.left
                  ? dayjs(data.mastectomy?.left).format('YYYY-MM-DD')
                  : null,
              },
              organ_reservation_operation: {
                right: data.organ_reservation_operation?.right
                  ? dayjs(data.organ_reservation_operation?.right).format('YYYY-MM-DD')
                  : null,
                left: data.organ_reservation_operation?.left
                  ? dayjs(data.organ_reservation_operation?.left).format('YYYY-MM-DD')
                  : null,
              },
              radiation_therapy: {
                right: data.radiation_therapy?.right
                  ? dayjs(data.radiation_therapy?.right).format('YYYY-MM-DD')
                  : null,
                left: data.radiation_therapy?.left
                  ? dayjs(data.radiation_therapy?.left).format('YYYY-MM-DD')
                  : null,
              },
              other_operations: {
                right: data.other_operations?.right
                  ? dayjs(data.other_operations?.right).format('YYYY-MM-DD')
                  : null,
                left: data.other_operations?.left
                  ? dayjs(data.other_operations?.left).format('YYYY-MM-DD')
                  : null,
              },

              scar_mole_papilloma: {
                right: data.scar_mole_papilloma?.right || false,
                left: data.scar_mole_papilloma?.left || false,
              },
              nipple_changes: {
                right: data.nipple_changes?.right || false,
                left: data.nipple_changes?.left || false,
              },
              nipple_discharge: {
                right: data.nipple_discharge?.right || false,
                left: data.nipple_discharge?.left || false,
              },
              deformation_compaction: {
                right: data.deformation_compaction?.right || false,
                left: data.deformation_compaction?.left || false,
              },

              assistant_pic: data.assistant_pic
                ? data.assistant_pic.substring(data.assistant_pic.indexOf(',') + 1)
                : null,
              direct_projection: {
                right: data.direct_projection?.right ? +data.direct_projection.right : null,
                left: data.direct_projection?.left ? +data.direct_projection.left : null,
              },
              oblique_projection: {
                right: data.oblique_projection?.right ? +data.oblique_projection.right : null,
                left: data.oblique_projection?.left ? +data.oblique_projection.left : null,
              },
              mammo_total_number: data.mammo_total_number ? +data.mammo_total_number : null,
              assistant_note: data.assistant_note || null,
              assistant_date: data.assistant_date
                ? dayjs(data.assistant_date).format('YYYY-MM-DD')
                : null,
              closed_on_damumed: data.closed_on_damumed || false,
              visit_type: data.visit_type || null,
            }
          : null,
    });
  }

  const downloadPdf = trpc.mammo.generatePdf.useMutation({
    onSettled: (pdf, error) => {
      if (error) {
        notifications.show({
          message: 'Что-то пошло не так',
          color: 'red',
        });
      }

      if (pdf) {
        const downloadLink = document.createElement('a');
        downloadLink.href = `data:application/pdf;base64,${pdf}`;
        downloadLink.download = `${study?.patients?.iin || ''} Направление на вторую читку.pdf`;
        downloadLink.click();
      }
    },
  });

  return (
    <div className="text-white">
      <form>
        <FormProvider {...f}>
          {!showPartial && (
            <NewVisitModalitySlugsData
              disabled={!!study || mainStepCompleted}
              study={study}
            />
          )}

          {!mainStepCompleted && (
            <div className="mt-4 flex flex-col items-center sm:flex-row">
              <div className="text-right sm:w-1/3"></div>
              <div className="flex-1 sm:ml-3">
                <Button
                  size="xs"
                  onClick={async () => {
                    if ((await f.trigger('serviceModality')) && (await f.trigger('iin'))) {
                      setMainStepCompleted(true);
                    }
                  }}
                  rightIcon={<IconArrowDown size={17} />}
                >
                  Продолжить
                </Button>
              </div>
            </div>
          )}

          <div className={`${!mainStepCompleted ? 'pointer-events-none opacity-20' : ''}`}>
            {!showPartial && <NewVisitMainData loading={visitPatient.isInitialLoading} />}
            {showPartial !== 'mammo' && (
              <>
                <NewVisitPaymentTypeData />
                <Skeleton
                  visible={visitApts.isInitialLoading}
                  className="mt-4"
                >
                  <NewVisitAppointments
                    appointments={visitApts.data || []}
                    disabled={study?.status === 'FINISHED'}
                    study_id={study?.id}
                  />

                  {visitApts.data?.length === 0 && payment_type_slug === 'OSMS' && (
                    <p className="ml-6 mt-2 text-red-500">
                      По данному пациенту нет направлений в базе. Данное исследование нужно будет
                      вручную закрывать в Дамумед
                    </p>
                  )}

                  {visitApts.data && (
                    <NewVisitServiceData
                      appointments={visitApts.data}
                      study={study}
                    />
                  )}
                </Skeleton>
              </>
            )}

            <div className="mt-8">
              {showPartial !== 'mammo' && (
                <NewVisitTreatmentReason
                  loading={visitPatient.isInitialLoading || visitApts.isInitialLoading}
                />
              )}
              {!showPartial && <NewVisitAnamnesisData loading={visitPatient.isInitialLoading} />}
            </div>

            {serviceModality === 'MG' && showPartial !== 'services' && (
              <fieldset className="mt-8 border-t">
                <legend className="mx-auto px-4 text-xl font-bold">
                  {t('Second reading appointment')}
                </legend>

                <MammoVisitMainData />
                <MammoVisitAnamnesis />
                <MammoVisitVisibleChanges />
                <MammoVisitAssistant study={study} />
                <MammoVisitFirstReader
                  study={study}
                  showPartial={showPartial}
                />
                <MammoVisitSecondReader
                  study={study}
                  showPartial={showPartial}
                />
              </fieldset>
            )}

            {showPartial !== 'services' && <NewVisitDoctorData study={study} />}
          </div>
        </FormProvider>

        <div className="mt-4 flex flex-col items-center sm:flex-row">
          <div className="text-right sm:w-1/3"></div>
          <div className="flex-1 sm:ml-3">
            {(showPartial !== 'mammo' || user.data?.roleHelper.isAssistant) &&
              mainStepCompleted && (
                <Button
                  className="mr-3"
                  loading={upsertVisit.isLoading}
                  onClick={onSubmit}
                >
                  {t('Save')}
                </Button>
              )}

            {study?.is_mammo && (
              <Button
                variant="outline"
                className="mr-3"
                loading={downloadPdf.isLoading}
                onClick={() => downloadPdf.mutate({ studyId: study?.id })}
              >
                {t('Download PDF')}
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export function EditIinForm({ study }: { study: Studies }) {
  const [existingPatient, setExistingPatient] = useState<
    inferRouterOutputs<AppRouter>['patient']['getMany'][number] | null
  >(null);
  const trpcUtils = trpc.useUtils();
  const f = useForm<InferType<typeof IinFormSchema>>({
    resolver: yupResolver(IinFormSchema),
    defaultValues: {
      iin: study.patients?.iin || '',
      document_type: study.patients?.document_type,
    },
  });
  const { t } = useTranslation('Custom');
  const document_type = f.watch('document_type');
  const iin = f.watch('iin');
  useEffect(() => {
    setExistingPatient(null);
    f.trigger();
  }, [iin, document_type, f]);

  const updateIin = trpc.patient.updateIin.useMutation({
    onSettled: (_, error) => {
      notifications.show({
        message: error ? 'Что-то пошло не так' : 'Данные сохранены',
        color: error ? 'red' : 'green',
      });
      modals.closeAll();
      trpcUtils.study.getAll.invalidate();
      trpcUtils.study.get.invalidate();
    },
  });

  async function handleUpdateIin(data: InferType<typeof IinFormSchema>) {
    updateIin.mutate({
      studyId: study.id,
      iin: data.iin,
      documentType: data.document_type,
    });
  }

  async function onSubmit(data: InferType<typeof IinFormSchema>) {
    if (existingPatient) {
      handleUpdateIin(data);
    } else if (
      study.patients?.iin !== data.iin ||
      study.patients?.document_type !== data.document_type
    ) {
      const existingPatients = await trpcUtils.client.patient.getMany.query({
        iin: data.iin,
        documentType: data.document_type,
      });
      existingPatients[0] ? setExistingPatient(existingPatients[0]) : handleUpdateIin(data);
    } else {
      handleUpdateIin(data);
    }
  }

  return (
    <form onSubmit={f.handleSubmit(onSubmit)}>
      <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
        <Input.Label
          className="sm:w-1/3"
          required
        >
          {t('Document type')}
        </Input.Label>
        <div className="flex flex-wrap gap-4 sm:w-2/3">
          <Radio
            {...f.register('document_type')}
            label={t('IIN')}
            value="IIN"
          />
          <Radio
            {...f.register('document_type')}
            label={t('Passport')}
            value="PASSPORT"
          />
        </div>
      </div>

      <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
        <Input.Label
          className="sm:w-1/3"
          required
        >
          {document_type === 'IIN' ? t('IIN') : t('Passport')}
        </Input.Label>
        <TextInput
          {...f.register('iin')}
          error={f.formState.errors.iin?.message}
          className="sm:w-2/3"
        />
      </div>

      <div className="mt-4 flex flex-col items-center sm:flex-row">
        <div className="text-right sm:w-1/3"></div>
        <div className="flex-1 sm:ml-3">
          {!existingPatient && (
            <Button
              loading={updateIin.isLoading || f.formState.isSubmitting}
              type="submit"
            >
              {t('Save')}
            </Button>
          )}

          {existingPatient && (
            <Box>
              <Text
                size="sm"
                color="orange"
              >
                По данному {document_type === 'IIN' ? 'ИИН' : 'номеру паспорта'} в базе найден
                пациент с ФИО &quot;{existingPatient.fullname}&quot;. Хотите перепривязать текущее
                обследование этому пациенту?
              </Text>

              <Flex className="mt-2 gap-3">
                <Button
                  loading={updateIin.isLoading}
                  size="sm"
                  type="submit"
                >
                  Да
                </Button>
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    f.reset({
                      iin: study.patients?.iin || '',
                      document_type: study.patients!.document_type || undefined,
                    });
                    setExistingPatient(null);
                  }}
                >
                  Отмена
                </Button>
              </Flex>
            </Box>
          )}
        </div>
      </div>
    </form>
  );
}

const IinFormSchema = object({
  document_type: string<(typeof documentTypes)[number]>().defined().oneOf(documentTypes),
  iin: string()
    .when('document_type', (document_type, schema) => {
      return document_type[0] === 'IIN'
        ? schema
            .test(
              'is-valid-iin',
              'Введите корректный иин',
              val => val?.length === 12 && checkIinBinSum(val)
            )
            .required()
        : schema.min(1, 'Это обязательное поле').required();
    })
    .required(),
});
