import React from 'react';
import { useFormContext } from 'react-hook-form';
import { NewVisitFormType } from '../../schema';
import { Radio, Input, Textarea } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export default function MammoVisitMainData() {
  const {
    formState: { errors },
    watch,
    register,
    setValue,
  } = useFormContext<NewVisitFormType>();
  const { t } = useTranslation('Custom');

  const fieldBlocks: {
    radioName: keyof NewVisitFormType;
    radioLabel: string;
    inputs: { inputName: keyof NewVisitFormType; inputLabel: string }[];
    showInputsOn: 'no' | 'yes' | null;
  }[] = [
    {
      radioName: 'is_first_mammo',
      radioLabel: `${t('Is first mammo')}?`,
      inputs: [],
      showInputsOn: null,
    },
    {
      radioName: 'is_first_screening',
      radioLabel: `${t('Is first screening')}?`,
      inputs: [{ inputName: 'first_screening_data', inputLabel: t('Date, place, which time') }],
      showInputsOn: 'no',
    },
    {
      radioName: 'has_other_mg_studies',
      radioLabel: t('Other MG studies'),
      inputs: [{ inputName: 'other_mg_studies_data', inputLabel: t('When, where') }],
      showInputsOn: 'yes',
    },
    {
      radioName: 'has_prev_mg_studies',
      radioLabel: t('Has previous mg studies'),
      inputs: [],
      showInputsOn: null,
    },
    {
      radioName: 'has_menopause',
      radioLabel: t('Menopause'),
      inputs: [{ inputName: 'menopause_age', inputLabel: t('Age') }],
      showInputsOn: 'yes',
    },
    {
      radioName: 'heredity',
      radioLabel: `${t('Heredity is burdened')}?`,
      inputs: [
        { inputName: 'breast_cancer', inputLabel: t('Breast cancer (who?)') },
        { inputName: 'breast_cancer_other', inputLabel: t('Other place (who?)') },
      ],
      showInputsOn: 'yes',
    },
    {
      radioName: 'hormone_replacement_therapy',
      radioLabel: t('Hormone replacement therapy'),
      inputs: [],
      showInputsOn: null,
    },
    {
      radioName: 'has_complaints',
      radioLabel: t('Claim'),
      inputs: [{ inputName: 'complaints_data', inputLabel: t('Specify') }],
      showInputsOn: 'yes',
    },
  ];

  return (
    <>
      <fieldset className="mt-12 border p-4">
        <legend className="px-4 font-bold">{t('Common data')}</legend>

        {fieldBlocks.map(fieldBlock => {
          return (
            <div
              className="mt-4 flex flex-col gap-3 sm:flex-row"
              key={fieldBlock.radioName}
            >
              <Input.Label
                className="sm:w-1/3"
                required
              >
                {fieldBlock.radioLabel}
              </Input.Label>
              <div className="w-2/3">
                <div>
                  <div className="flex gap-4">
                    <Radio
                      {...register(fieldBlock.radioName)}
                      label={t('Yes')}
                      value="yes"
                      onClick={() =>
                        fieldBlock.inputs.forEach(i => {
                          setValue(i.inputName, '');
                        })
                      }
                    />

                    <Radio
                      {...register(fieldBlock.radioName)}
                      label={t('No')}
                      value="no"
                      onClick={() =>
                        fieldBlock.inputs.forEach(i => {
                          setValue(i.inputName, '');
                        })
                      }
                    />
                  </div>
                  <Input.Error className="mt-2">
                    {errors[fieldBlock.radioName]?.message}
                  </Input.Error>
                </div>

                {fieldBlock.inputs.map(i => {
                  const radioValue = watch(fieldBlock.radioName);

                  return (
                    <div
                      className="mt-4"
                      key={i.inputName}
                    >
                      {radioValue === fieldBlock.showInputsOn && (
                        <Textarea
                          {...register(i.inputName)}
                          error={errors[i.inputName]?.message}
                          placeholder={i.inputLabel}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </fieldset>
    </>
  );
}
