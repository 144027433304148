import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NewVisitFormType, serviceModalities } from '../schema';
import { Radio, Input, Select, TextInput, ActionIcon } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { trpc } from '../../../app/trpc';
import { Studies } from 'platform/app/src/types/StudyTypes';
import { IconPencil } from '@tabler/icons-react';
import { EditIinForm } from '..';
import { modals } from '@mantine/modals';

export default function NewVisitModalitySlugsData({
  disabled = false,
  study,
}: {
  disabled?: boolean;
  study?: Studies;
}) {
  const {
    register,
    control,
    watch,
    formState: { errors },
    trigger,
  } = useFormContext<NewVisitFormType>();
  const { t } = useTranslation('Custom');
  const user = trpc.general.getUser.useQuery();
  const document_type = watch('document_type');

  return (
    <>
      {user.data?.organization_user && user.data.organization_user.length > 1 && (
        <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
          <Input.Label
            className="sm:w-1/3"
            required
          >
            Организация
          </Input.Label>
          <Controller
            name="orgId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={user.data.organization_user.map(ou => ({
                  value: ou.organization_id?.toString() || '',
                  label: ou.organizations?.name || '',
                }))}
                className="sm:w-2/3"
                searchable
                disabled={disabled}
              />
            )}
          />
        </div>
      )}
      <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
        <Input.Label
          className="sm:w-1/3"
          required
        >
          {t('Type of study')}
        </Input.Label>
        <div className="sm:w-2/3">
          <div className="flex flex-wrap gap-4">
            {Object.entries(serviceModalities).map(([serviceName, modality]) => (
              <div key={serviceName}>
                <Radio
                  {...register('serviceModality')}
                  label={modality.label}
                  disabled={disabled}
                  value={serviceName}
                />
              </div>
            ))}
          </div>
          {errors.serviceModality?.message && (
            <Input.Error className="mt-2">Выберите тип исследования</Input.Error>
          )}
        </div>
      </div>
      <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
        <Input.Label
          className="sm:w-1/3"
          required
        >
          {t('Document type')}
        </Input.Label>
        <div className="flex flex-wrap gap-4 sm:w-2/3">
          <Radio
            {...register('document_type', { onChange: () => trigger('iin') })}
            label={t('IIN')}
            value="IIN"
            disabled={disabled}
          />
          <Radio
            {...register('document_type', { onChange: () => trigger('iin') })}
            label={t('Passport')}
            value="PASSPORT"
            disabled={disabled}
          />
        </div>
      </div>

      <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
        <Input.Label
          className="sm:w-1/3"
          required
        >
          {document_type === 'IIN' ? t('IIN') : t('Passport')}
        </Input.Label>
        <div className="flex items-start gap-4 sm:w-2/3">
          <TextInput
            classNames={{ root: 'w-full' }}
            {...register('iin', { onChange: () => trigger('iin') })}
            error={errors.iin?.message}
            disabled={disabled}
          />

          {study && (
            <ActionIcon
              color="blue"
              variant="filled"
              className="mt-[4px]"
              onClick={() => modals.open({ children: <EditIinForm study={study} /> })}
            >
              <IconPencil />
            </ActionIcon>
          )}
        </div>
      </div>
    </>
  );
}
