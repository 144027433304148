import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Box, Loader, Text } from '@mantine/core';
import { getInstanceId, getInstanceSrHTML } from '../utils/orthancUtils';

export default function SRViewer({ instanceUid }: { instanceUid: string }) {
  const res = useQuery({
    queryKey: ['get-sr-html', instanceUid],
    queryFn: async () => {
      const instanceId = await getInstanceId(instanceUid);
      return getInstanceSrHTML(instanceId.ID);
    },
  });

  if (res.isLoading) return <Loader className="my-32 mx-auto" />;
  if (res.isError) return <Text className="my-4">Что-то пошло не так</Text>;
  if (!res.data) return null;

  return (
    <Box
      dangerouslySetInnerHTML={{ __html: res.data }}
      className="sr-viewer"
    ></Box>
  );
}
