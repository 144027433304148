import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

export const areaDamages = {
  0: 'меньше 30 %',
  1: '31-50 %',
  2: '51-70 %',
  3: '71-90%',
  4: 'больше 90%',
};

const DetectedDiseaseSlice = createSlice({
  name: 'detectedDisease',
  initialState: initialState,
  reducers: {
    changePolygonVisibility(state, action) {
      state.data[action.payload.seriesInstanceUid][
        action.payload.instanceInstanceUid
      ][action.payload.frame][action.payload.type].isVisible =
        action.payload.isVisible;
    },
    changePolygonOpacity(state, action) {
      state.data[action.payload.seriesInstanceUid][
        action.payload.instanceInstanceUid
      ][action.payload.frame].opacity = action.payload.opacity;
    },
    setFramePolygonImageUrl(state, action) {
      state.data[action.payload.seriesInstanceUid][
        action.payload.instanceInstanceUid
      ][action.payload.frame].framePolygonImageUrl =
        action.payload.framePolygonImageUrl;
    },
    removeFramePolygonImageUrl(state, action) {
      state.data[action.payload.seriesInstanceUid][
        action.payload.instanceInstanceUid
      ][action.payload.frame].framePolygonImageUrl = null;
    },
    changeDiseaseAreaDamage(state, action) {
      state.data[action.payload.seriesInstanceUid][
        action.payload.instanceInstanceUid
      ][action.payload.frame].stenosisMetadata.diseaseData = action.payload.data;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDisease.fulfilled, (state, action) => {
      if (action.payload) {
        const series = state.data[action.payload.seriesInstanceUid] || {};
        const instance = series[action.payload.instanceInstanceUid] || {};
        const frame = instance[action.payload.frame] || {};

        state.data[action.payload.seriesInstanceUid] = {
          ...series,
          [action.payload.instanceInstanceUid]: {
            ...instance,
            [action.payload.frame]: {
              ...frame,
              [action.payload.type]: {
                diseaseData: action.payload.disease,
                zoom: action.payload.zoom,
                width: action.payload.width,
                height: action.payload.height,
                isVisible: action.payload.isVisible,
              },
              frameImageUrl: action.payload.frameImageUrl,
            },
          },
        };
      }
    });
  },
});

export const fetchDisease = createAsyncThunk(
  'post/fetchegmentDisease',
  async props => {
    const {
      studyInstanceUid,
      seriesInstanceUid,
      instanceInstanceUid,
      type,
      frameImageUrl,
      zoom,
      width,
      height,
      frame,
      catheter,
      angle,
    } = props;

    const fetchLinks = {
      segment: `${process.env.ML_BACKEND}/start_evaluation_segmentation`,
      stenosis: `${process.env.ML_BACKEND}/start_evaluation_stenosis`,
      stenosisMetadata: `${process.env.ML_BACKEND}/start_evaluation_disease`,
    };

    try {
      const response = await fetch(
        `${fetchLinks[type]}?` +
          new URLSearchParams({
            dicom_filename: `${studyInstanceUid},${seriesInstanceUid},${instanceInstanceUid}`,
            frame: frame,
            ...(type === 'segment' ? { catether_size: catheter, angle: angle } : {}),
          })
      );

      const disease = await response.json();

      const diseasePolygonProperties = {
        zoom,
        width,
        height,
        isVisible: disease?.annotations?.length !== 0,
      };

      if (disease) {
        return {
          disease,
          type,
          seriesInstanceUid,
          instanceInstanceUid,
          frame,
          frameImageUrl,
          ...((type === 'segment' || type === 'stenosis') &&
            diseasePolygonProperties),
        };
      } else {
        return null;
      }
    } catch (e) {}
  }
);

export const {
  changePolygonVisibility,
  changePolygonOpacity,
  setFramePolygonImageUrl,
  removeFramePolygonImageUrl,
  changeDiseaseAreaDamage,
} = DetectedDiseaseSlice.actions;

export default DetectedDiseaseSlice.reducer;
