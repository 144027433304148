import React, { useState, useEffect } from 'react';
import { Button, Checkbox } from '@mantine/core';
import { useFormContext } from 'react-hook-form';
import { CnFirstMammoForm, CnMammoForm } from '../../CnMammo';
import { CnMammoPaidForm } from '../../CnMammoPaid';
import { PreviewMammo, PreviewDefault, PreviewMammoPaid } from '../CnPreview/PreviewMammo';
import type { AppRouter } from '../../../../../../../../medreview/server/src';
import { inferRouterOutputs } from '@trpc/server';
import { trpc } from '../../../../../../../platform/app/trpc';
import { modals } from '@mantine/modals';
import { DefaultForm } from '../../CnDefault';
import type { UseFormReturn } from 'react-hook-form';

export function SubmitCnBtn({
  onSubmit,
  study,
}: {
  onSubmit: (data: DefaultForm, sendMessage: boolean) => void;
  study: inferRouterOutputs<AppRouter>['study']['get'];
}) {
  const { handleSubmit, getValues, trigger } = useFormContext<DefaultForm>();

  return (
    <Button
      size="xs"
      onClick={async () => {
        (await trigger()) &&
          modals.open({
            children: (
              <>
                <PreviewDefault
                  formData={getValues()}
                  study={study!}
                />
                <SubmitBtns
                  study={study}
                  onSubmit={(send_message: boolean) =>
                    handleSubmit(data => onSubmit(data, send_message))()
                  }
                />
              </>
            ),
            size: 1400,
            classNames: { body: 'p-0' },
          });
      }}
    >
      Сохранить
    </Button>
  );
}

export function SubmitMammoBtn({
  onSubmit,
  previewMetadata,
  study,
}: {
  onSubmit: (data: CnMammoForm, sendMessage: boolean) => void;
  previewMetadata: {
    first: UseFormReturn<CnFirstMammoForm>;
    second: UseFormReturn<CnMammoForm>;
  };
  study: inferRouterOutputs<AppRouter>['study']['get'];
}) {
  const { handleSubmit, getValues, trigger, setError } = useFormContext<CnMammoForm>();
  const birads = trpc.general.getBirads.useQuery();

  return (
    <>
      <Button
        size="xs"
        onClick={async () => {
          const data = getValues();
          const criticalBirad = birads.data
            ?.filter(b => [data.birads_code_right, data.birads_code_left].includes(b.id.toString()))
            ?.find(b => b.category === 'M4' || b.category === 'M5');
          if (criticalBirad && !data.check_value) {
            setError('check_value', { message: 'Это обязательно поле' });
            return;
          }

          (await trigger()) &&
            modals.open({
              children: (
                <>
                  <PreviewMammo previewMetadata={previewMetadata} />
                  <SubmitBtns
                    study={study}
                    onSubmit={(send_message: boolean) =>
                      handleSubmit(data => onSubmit(data, send_message))()
                    }
                  />
                </>
              ),
              size: 1400,
              classNames: { body: 'p-0' },
            });
        }}
      >
        Сохранить
      </Button>
    </>
  );
}

export function SubmitMammoPaidBtn({
  onSubmit,
  study,
  cnId,
}: {
  onSubmit: (data: CnMammoPaidForm, sendMessage: boolean) => void;
  study: inferRouterOutputs<AppRouter>['study']['get'];
  cnId?: number;
}) {
  const f = useFormContext<CnMammoPaidForm>();

  return (
    <>
      <Button
        size="xs"
        onClick={async () => {
          (await f.trigger()) &&
            modals.open({
              children: (
                <>
                  <PreviewMammoPaid
                    formValues={f.getValues()}
                    cnId={cnId}
                  />
                  <SubmitBtns
                    study={study}
                    onSubmit={(sendMessage: boolean) =>
                      f.handleSubmit(data => onSubmit(data, sendMessage))()
                    }
                  />
                </>
              ),
              size: 1400,
              classNames: { body: 'p-0' },
            });
        }}
      >
        Сохранить
      </Button>
    </>
  );
}

const SubmitBtns = ({
  study,
  onSubmit,
}: {
  study?: inferRouterOutputs<AppRouter>['study']['get'];
  onSubmit: (sendMessage: boolean) => void;
}) => {
  const user = trpc.general.getUser.useQuery();
  const isCheckboxVisible =
    !!study?.ohif_id &&
    (user.data?.roleHelper.isFirstReader
      ? !!study.conclusion.some(
          c => c.type === 'SECOND_READING' || c.type === 'SECOND_READING_PAID'
        )
      : true);
  const [loading, setLoading] = useState(false);
  const [sendMessage, setSendMessage] = useState<boolean>(
    isCheckboxVisible && !study?.modality_study.some(ms => ms.modalities.name === 'MG')
  );

  useEffect(() => {
    loading && onSubmit(sendMessage);
  }, [onSubmit, sendMessage, loading]);

  return (
    <div className="sticky bottom-0 flex items-center justify-end gap-8 bg-[#041c4a] p-4">
      {isCheckboxVisible && (
        <Checkbox
          label="Отправить WhatsApp сообщение пациенту"
          checked={sendMessage}
          onChange={event => setSendMessage(event.currentTarget.checked)}
        />
      )}
      <Button
        size="xs"
        loading={loading}
        onClick={() => setLoading(true)}
      >
        Отправить заключение
      </Button>
    </div>
  );
};
