import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { NewVisitFormType } from '../schema';
import { Radio, Select } from '@mantine/core';
import { trpc } from '../../../app/trpc';

export default function NewVisitPaymentTypeData() {
  const {
    control,
    formState: { errors },
    watch,
    register,
    setValue,
  } = useFormContext<NewVisitFormType>();

  const payment_type_slug = watch('payment_type_slug');
  const paymentTypes = trpc.general.getPaymentTypes.useQuery();
  const insurances = trpc.general.getInsuranceOrgs.useQuery();

  return (
    <>
      <div className="mt-12 flex flex-wrap items-center gap-4">
        {paymentTypes.data &&
          paymentTypes.data.map(p => (
            <div
              className="flex items-center gap-3"
              key={p.payment_type}
            >
              <Radio
                {...register('payment_type_slug')}
                label={p.payment_type_rus?.toLowerCase()}
                value={p.payment_type || ''}
                onClick={() => setValue('insurance_organizations_id', '')}
              />
            </div>
          ))}
      </div>
      {payment_type_slug === 'INSURANCE' && (
        <Controller
          name="insurance_organizations_id"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={(insurances.data || []).map(i => ({
                value: String(i.id),
                label: i.name,
              }))}
              placeholder="Выберите страховую компанию"
              error={errors.insurance_organizations_id?.message}
              className="mt-2"
              searchable
            />
          )}
        />
      )}
    </>
  );
}
