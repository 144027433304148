import { useMemo } from 'react';
import { trpc } from '../app/trpc';

export function useGetBiradsLocs() {
  const biradsLocals = trpc.general.getBiradsLocs.useQuery();

  const options = useMemo(() => {
    return {
      birads:
        biradsLocals.data?.birads.map(birad => ({
          value: String(birad.id),
          label: birad.category + ': ' + birad.description,
        })) || [],
      localizations:
        biradsLocals.data?.localization.map(loc => ({
          value: String(loc.id),
          label: loc.ru || '',
        })) || [],
    };
  }, [biradsLocals.data]);

  return { options, isLoading: biradsLocals.isLoading };
}
