import { servicesManager } from '../app/src/App';
import axios from 'axios';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../medreview/server/src';
import { ofetch } from 'ofetch';
import { extensionManager } from '../app/src/App';
import { getHeader, tokenBased } from './utils';

export async function getOptimalDataSource(
  orthancConfig:
    | NonNullable<
        NonNullable<inferRouterOutputs<AppRouter>['study']['get']>['organizations']
      >['orthanc_config'][number]
    | null,
  userOrganizations: NonNullable<
    NonNullable<inferRouterOutputs<AppRouter>['general']['getUser']>['organization_user']
  >,
  studyUid: string | null
) {
  const localDataSource = orthancConfig?.dicom_hostname
    ? `${orthancConfig.dicom_hostname}${tokenBased ? '/kc' : ''}`
    : null;
  const proxyVpnDataSource = orthancConfig?.local_dicomweb_proxy
    ? `${orthancConfig.local_dicomweb_proxy}${tokenBased ? '/kc' : ''}`
    : null;
  const mlDataSource = `${process.env.ML_ORTHANC_URL}/pacs`;
  const cloudDataSource = `${process.env.MR_OLD_URL}/pacs${tokenBased ? '/kc' : ''}`;

  if (studyUid && !localDataSource) {
    localStorage.removeItem('localOrthanc');
  }

  if (
    localStorage.getItem('localOrthanc') &&
    localDataSource &&
    (await checkDataSource(localDataSource, studyUid))
  ) {
    return localDataSource;
  }

  if (proxyVpnDataSource && (await checkDataSource(proxyVpnDataSource, studyUid))) {
    return proxyVpnDataSource;
  }

  return userOrganizations.some(o => o.organizations?.name === 'ml_test')
    ? mlDataSource
    : cloudDataSource;
}

export async function checkDataSource(url: string, studyUid: string | null) {
  let isOk = false;

  // TODO: wait for the types oh OHIF services
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { userAuthenticationService } = servicesManager.services as any;

  try {
    const res = studyUid
      ? await axios.post(`${url}/tools/lookup`, studyUid, {
          headers: userAuthenticationService?.getAuthorizationHeader(),
          timeout: 5000,
        })
      : await axios.get(`${url}/tools/now-local`, {
          headers: userAuthenticationService?.getAuthorizationHeader(),
        });

    isOk = Array.isArray(res.data) ? res.data.length > 0 : !!res.data;
  } catch (error) {
    isOk = false;
    console.log(error);
  }

  return isOk;
}

export async function getInstanceId(uid: string): Promise<InstanceUidTypes> {
  const authH = await getHeader();
  const dataSourceUrl = getDataSource();
  const data = await ofetch(`${dataSourceUrl}/tools/lookup`, {
    method: 'POST',
    body: uid,
    headers: { Authorization: authH! },
  });

  return data[0];
}

export async function getInstanceSrHTML(id: string): Promise<string> {
  const authH = await getHeader();
  const dataSourceUrl = getDataSource();
  const data = await ofetch(`${dataSourceUrl}/get-sr-html/${id}`, {
    method: 'GET',
    headers: { Authorization: authH! },
  });

  return data
}

function getDataSource() {
  const dataSource = extensionManager.getActiveDataSource()[0];
  return dataSource.getConfig().qidoRoot.replace(/\/dicom-web$/, '');
}

export interface InstanceUidTypes {
  ID: string;
  Path: string;
  Type: string;
}

export async function getStudyInstancesTags(StudyInstanceUid: string, dataSource: string) {
  const authH = await getHeader();
  const studyIds = await ofetch<{ ID: number }[]>(`${dataSource}/tools/lookup`, {
    method: 'POST',
    body: StudyInstanceUid,
    headers: { Authorization: authH! },
  });

  if (!studyIds?.length) {
    return null;
  }

  let study = await ofetch<{ Series: string[] }>(`${dataSource}/studies/${studyIds[0].ID}`, {
    headers: { Authorization: authH! },
  });

  if (!study) {
    return null;
  }

  let resStudy = { ...study, mapped: { series: [] as any } };

  for (const seriesID of study.Series) {
    const series = await ofetch<{ Instances: string[] }>(`${dataSource}/series/${seriesID}`, {
      headers: { Authorization: authH! },
    });
    const instancesTags = [];
    for (const instanceId of series.Instances) {
      const instanceTags = await ofetch(`${dataSource}/instances/${instanceId}/simplified-tags`, {
        headers: { Authorization: authH! },
      });
      instancesTags.push(instanceTags);
    }

    resStudy.mapped.series.push({ ...series, mapped: { instancesTags: instancesTags } });
  }

  return resStudy;
}
