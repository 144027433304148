import Keycloak from 'keycloak-js';

const _kc = new Keycloak({
  url: `${process.env.MR_OLD_URL}/auth`,
  realm: 'ohif',
  clientId: 'ohif-viewer',
});

_kc.onAuthSuccess = function () {
  let interval = 10000;
  let expected = Date.now() + interval;
  setTimeout(step, interval);
  function step() {
    let dt = Date.now() - expected;
    _kc.updateToken(60);

    expected += interval;
    setTimeout(step, Math.max(0, interval - dt));
  }
};

const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    })
    .then(authenticated => {
      if (!authenticated) {
        console.log('user is not authenticated..!');
        doLogin();
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = _kc.login;
const doLogout = _kc.logout;
const getToken = () => _kc.token;
const getTokenParsed = () => _kc.tokenParsed;
const isLoggedIn = () => !!_kc.token;
const updateToken = () => _kc.updateToken(30);

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
};

export default UserService;
