import React, { useEffect, useRef } from 'react';
import { getRenderTools } from '../utils';

interface Props {
  imgUrl: string;
  properties: any;
  prefix: string;
  size: { width: string; height: string };
}

export function Viewer({ imgUrl, properties, prefix, size }: Props) {
  const viewer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!viewer.current) {
      return;
    }

    const { renderingEngine, enabledElem, toolGroup, defaultOptions } = getRenderTools();

    const viewportId = `${prefix}-${imgUrl}`;
    const element = viewer.current;

    toolGroup?.addViewport(viewportId, enabledElem.renderingEngineId);
    renderingEngine?.enableElement({
      viewportId,
      element,
      type: enabledElem.viewport.type,
      defaultOptions,
    });

    const viewport = renderingEngine?.getViewport(viewportId);

    viewport?.setStack([imgUrl]).then(() => {
      viewport.setProperties(properties);
    });

    return () => {
      const viewportId = `${prefix}-${imgUrl}`;

      toolGroup?.removeViewports(enabledElem.renderingEngineId, viewportId);
      renderingEngine?.disableElement(viewportId);
    };
  }, [imgUrl, prefix, properties]);

  return (
    <div className="relative">
      <div
        ref={viewer}
        style={{ height: size.height, width: size.width }}
        data-viewer-id={`${prefix}-${imgUrl}`}
      ></div>

      <div className="absolute top-0 left-0 h-full w-full"></div>
    </div>
  );
}
