import React, { useState } from 'react';
import CnMammo from './CnMammo';
import CnDefault from './CnDefault';
import { useGetStudy } from '../../../../../platform/app/src/api/studies';
import { Box, Button, Flex } from '@mantine/core';
import { useLocation } from 'react-router';
import CnMammoPaid from './CnMammoPaid';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../../../medreview/server/src';

export default function PanelConclusion({
  studyId,
  onSave,
}: {
  studyId?: number;
  onSave?: () => void;
}) {
  const location = useLocation();
  const isConclusionOnlyPage = location.pathname.startsWith('/conclusion');
  const study = useGetStudy(studyId, !!studyId);

  if (!study.data) {
    return null;
  }

  return (
    <Box className={`${isConclusionOnlyPage ? 'mx-auto w-full max-w-[900px]' : ''}`}>
      {study?.data.modality_study.some(ms => ms.modalities.name === 'MG') ? (
        <CnMammoWrapper
          study={study.data}
          isConclusionOnlyPage={isConclusionOnlyPage}
        />
      ) : (
        <CnDefault
          study={study.data}
          onSave={() => onSave && onSave()}
          isConclusionOnlyPage={isConclusionOnlyPage}
        />
      )}
    </Box>
  );
}

function CnMammoWrapper({
  study,
  isConclusionOnlyPage,
}: {
  study: NonNullable<inferRouterOutputs<AppRouter>['study']['get']>;
  isConclusionOnlyPage: boolean;
}) {
  const existingCn = study.conclusion[0];
  const [showForm, setShowForm] = useState<'screening' | 'paid'>(() => {
    if (existingCn) {
      return existingCn.type === 'FIRST_READING' || existingCn.type === 'SECOND_READING'
        ? 'screening'
        : 'paid';
    } else {
      const anamnesis = study.anamesis[0] || study.patients?.anamesis[0];
      return anamnesis?.treatment_reason_code?.toString() === '17' ? 'screening' : 'paid';
    }
  });

  return (
    <>
      {!existingCn && (
        <Flex className="mt-4 justify-end pr-8">
          <Button
            onClick={() => setShowForm(showForm === 'screening' ? 'paid' : 'screening')}
            size="xs"
          >
            Открыть форму &quot;{showForm === 'screening' ? 'Для платных' : 'Скрининг'}&quot;
          </Button>
        </Flex>
      )}

      {showForm === 'screening' ? (
        <CnMammo isConclusionOnlyPage={isConclusionOnlyPage} />
      ) : (
        <CnMammoPaid isConclusionOnlyPage={isConclusionOnlyPage} />
      )}
    </>
  );
}
