import React from 'react';
import { Loader } from '@mantine/core';

export function inputLoaderState(loading: boolean) {
  return {
    icon: <InputLoader loading={loading} />,
    classNames: { input: loading ? '!pl-[1.875rem]' : '' },
    readOnly: loading,
  };
}

export default function InputLoader({ loading }: { loading: boolean }) {
  return loading ? (
    <Loader
      size="xs"
      variant="dots"
    />
  ) : null;
}
