import React, { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CnFirstMammoForm } from '../CnMammo';
import { MultiSelect } from '@mantine/core';

export default function CnMammoRecs({ disabled }: { disabled: boolean }) {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<CnFirstMammoForm>();

  const recs = watch('recs');
  const recsSelectInput = useRef<HTMLInputElement | null>(null);

  const editableOption = 'Краткосрочный динамический контроль через ';
  const [recsOptions, setRecsOptions] = useState<string[]>([
    'Пригласить на скрининг через 2 года',
    'Пригласить на дополнительное обследование в СКДО (МГ, УЗИ, биопсия)',
    `${editableOption}`,
    'Переснять маммограммы (технические проблемы, неполный охват)',
  ]);

  return (
    <div className="mt-4">
      <MultiSelect
        ref={recsSelectInput}
        creatable
        value={recs}
        label="Рекомендации"
        data={[...recsOptions, ...recs]}
        error={errors.recs?.message}
        readOnly={disabled}
        searchable
        onChange={v => {
          if (v.includes(editableOption) && recsSelectInput.current) {
            recsSelectInput.current.value = editableOption;
            return;
          }
          setValue('recs', v);
        }}
        getCreateLabel={query => `+ Нажмите, чтобы добавить "${query}"`}
        onCreate={query => {
          if (query !== editableOption) {
            setRecsOptions(current => [...current, query]);
            setValue('recs', [...recs, query]);
            return query;
          }
        }}
      />
    </div>
  );
}
