import React, { useState, useEffect } from 'react';
import { object, InferType, array } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { trpc } from '../../app/trpc';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { Button, MultiSelect } from '@mantine/core';

export const schema = object({
  reason: array().min(1, 'Укажите причину отмены').required(),
});

export type CancelStudyForm = InferType<typeof schema>;

const CancelStudyModal = ({ id, status }: { status: string; id: number }) => {
  const trpcUtils = trpc.useUtils();
  const { data: cancelReasons } = trpc.study.cancelReasons.useQuery();
  const [data, setData] = useState<string[]>([]);
  useEffect(() => {
    if (cancelReasons) {
      setData(cancelReasons);
    }
  }, [cancelReasons]);

  const {
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      reason: [],
    },
  });

  const cancelOrResumeStudy = trpc.study.cancelOrResume.useMutation({
    onSettled: (_, error) => {
      notifications.show({
        message: !error ? 'Изменения сохранены' : 'Что-то пошло не так',
        color: !error ? 'green' : 'red',
      });

      trpcUtils.study.getAll.invalidate();
      modals.closeAll();
    },
  });

  const resumeStudy = () => {
    cancelOrResumeStudy.mutate({
      studyId: id,
      newStatus: 'RESUMED',
    });
  };

  const cancelStudy = (data: CancelStudyForm) => {
    cancelOrResumeStudy.mutate({
      studyId: id,
      newStatus: 'CANCELLED',
      reason: data.reason.join(' ||| '),
    });
  };

  if (status !== 'CANCELLED') {
    return (
      <form onSubmit={handleSubmit(cancelStudy)}>
        <MultiSelect
          label="Причина отмены"
          creatable
          searchable
          getCreateLabel={query => `+ Добавить ${query}`}
          onChange={v => setValue('reason', v)}
          onCreate={query => {
            setData(current => [...current, query]);
            setValue('reason', [...getValues()['reason'], query]);
            return query;
          }}
          error={errors.reason?.message}
          data={data}
        />

        <Button
          loading={cancelOrResumeStudy.isLoading}
          type="submit"
          className="mt-8"
        >
          Подтвердить отмену
        </Button>
      </form>
    );
  }

  if (status === 'CANCELLED') {
    return (
      <div>
        <h3>Вы уверены, что хотите вернуть отмененное обследование?</h3>
        <Button
          className="mt-8"
          loading={cancelOrResumeStudy.isLoading}
          onClick={resumeStudy}
        >
          Подтвердить
        </Button>
      </div>
    );
  }

  return null;
};
export default CancelStudyModal;
