import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { serviceModalities, NewVisitFormType } from '../schema';
import { Input, Select, Loader } from '@mantine/core';
import { Studies } from 'platform/app/src/types/StudyTypes';
import { useTranslation } from 'react-i18next';
import { trpc } from '../../../app/trpc';

const NewVisitDoctorData = ({ study }: { study?: Studies }): JSX.Element => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<NewVisitFormType>();

  const serviceModality = watch('serviceModality');
  const currentOrgId = watch('orgId');
  const { t } = useTranslation('Custom');

  const doctors = trpc.general.getUsers.useQuery(
    {
      modalitySlugs: (serviceModalities[serviceModality]?.modality_slugs as any) || [],
      orgIds: [+currentOrgId],
      canMakeConclusions: true,
    },
    { enabled: !!serviceModality }
  );

  const labs = trpc.general.getUsers.useQuery(
    {
      modalitySlugs: (serviceModalities[serviceModality]?.modality_slugs as any) || [],
      orgIds: [+currentOrgId],
      roleSlugs: ['assistant'],
    },
    { enabled: !!serviceModality }
  );

  return (
    <>
      {serviceModality === 'MG' && (
        <>
          <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
            <Input.Label className="sm:w-1/3">{t('First reader doctor')}</Input.Label>
            <Controller
              name="first_reader_responsible_user_id"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    data={(doctors.data || []).map(doctor => ({
                      value: String(doctor.id),
                      label: doctor.fullname || '',
                    }))}
                    error={errors[field.name]?.message}
                    className="sm:w-2/3"
                    icon={
                      doctors.isInitialLoading ? (
                        <Loader
                          size="xs"
                          variant="dots"
                        />
                      ) : null
                    }
                  />
                );
              }}
            />
          </div>

          <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
            <Input.Label className="sm:w-1/3">{t('Second reader doctor')}</Input.Label>
            <Controller
              name="responsible_user_id"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    data={(doctors.data || []).map(doctor => ({
                      value: String(doctor.id),
                      label: doctor.fullname || '',
                    }))}
                    error={errors[field.name]?.message}
                    className="sm:w-2/3"
                    icon={
                      doctors.isInitialLoading ? (
                        <Loader
                          size="xs"
                          variant="dots"
                        />
                      ) : null
                    }
                  />
                );
              }}
            />
          </div>
        </>
      )}

      {serviceModality !== 'MG' && (
        <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
          <Input.Label className="sm:w-1/3">{t('Responsible doctor')}</Input.Label>
          <Controller
            name="responsible_user_id"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  data={(doctors.data || []).map(doctor => ({
                    value: String(doctor.id),
                    label: doctor.fullname || '',
                  }))}
                  error={errors[field.name]?.message}
                  className="sm:w-2/3"
                  icon={
                    doctors.isInitialLoading ? (
                      <Loader
                        size="xs"
                        variant="dots"
                      />
                    ) : null
                  }
                />
              );
            }}
          />
        </div>
      )}

      {!!study && (
        <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
          <Input.Label className="sm:w-1/3">{t('Assistant')}</Input.Label>
          <Controller
            name="physician_user_id"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  data={(labs.data || []).map(doctor => ({
                    value: String(doctor.id),
                    label: doctor.fullname || '',
                  }))}
                  error={errors[field.name]?.message}
                  className="sm:w-2/3"
                  icon={
                    labs.isInitialLoading ? (
                      <Loader
                        size="xs"
                        variant="dots"
                      />
                    ) : null
                  }
                />
              );
            }}
          />
        </div>
      )}
    </>
  );
};
export default NewVisitDoctorData;
